export const createAdminStart = () => {
  return {
    type: 'CREATE_USER_ADMIN_STARTED',
  }
}

export const createAdminSuc = (user: any) => {
  return {
    type: 'CREATE_USER_ADMIN_SUCCESS',
    payload: { user },
  }
}

export const createAdminError = (error: any) => {
  return {
    type: 'CREATE_USER_ADMIN_ERROR',
    payload: {
      error,
    },
  }
}
