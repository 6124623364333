import {
  getPromotionStart,
  getPromotionSuccess,
  getPromotionError,
} from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getPromotion = (id: number) => {
  return (dispatch: any) => {
    dispatch(getPromotionStart())
    getData(`promotions/${id}`)
      .then((result) => {
        dispatch(getPromotionSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getPromotionError(e))
      })
  }
}
export default getPromotion
