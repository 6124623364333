export const updatePasswordStart = () => {
  return {
    type: 'UPDATE_PASSWORD_STARTED',
  }
}

export const updatePasswordSuccess = () => {
  return {
    type: 'UPDATE_PASSWORD_SUCCESS',
    // payload: { session }
  }
}

export const updatePasswordError = (error: any) => {
  return {
    type: 'UPDATE_PASSWORD_ERROR',
    payload: {
      error,
    },
  }
}
