import React from 'react';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';

type ContainerProtos = {
    containerName: String;
    buttons: Array<{ label: string; to: string; state?: string; viewLabel?: any }>;
};
type ButtonsHeaderProps = {
    buttonShowsArray: Array<ContainerProtos>;
    buttonSelected: string;
    idTo?: string;
    imAdmin?: boolean;
};
const ButtonsHeader = ({ buttonShowsArray, buttonSelected, idTo = '', imAdmin = false }: ButtonsHeaderProps) => {
    const history = useHistory();
    const capitalize = (s: string) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
        <nav className='button-navbar'>
            {buttonShowsArray.map((value: ContainerProtos, key: number) => (
                <div className='button-navbar-row' key={key}>
                    {value.buttons.map(
                        (buttonLabel: { label: string; viewLabel?: any; to: string; state?: string }, key: number) => (
                            <>
                                {buttonLabel?.state?.toLowerCase() === 'off' ? (
                                    <></>
                                ) : (
                                    <button
                                        key={key}
                                        className={`${
                                            buttonSelected === buttonLabel.label
                                                ? !imAdmin
                                                    ? 'button-secondary'
                                                    : 'button-admin-tab'
                                                : !imAdmin
                                                ? `button-secondary-disabled`
                                                : 'button-admin-tab-disabled'
                                        }`}
                                        onClick={() => history.push(`${buttonLabel.to}${idTo}`)}
                                    >
                                        {capitalize(intl.get(buttonLabel.viewLabel))}
                                    </button>
                                )}
                            </>
                        ),
                    )}
                </div>
            ))}
        </nav>
    );
};

export default ButtonsHeader;
