import { CancelVisitStart, CancelVisitSuccess, CancelVisitError } from './types';
import { putData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
const cancelVisit = (visitId: number) => {
    return (dispatch: any) => {
        dispatch(CancelVisitStart());
        putData(`unit_visits/${visitId}/cancel`)
            .then((result: any) => {
                toast(intl.get('VISIT_CANCELED'));
                dispatch(CancelVisitSuccess(result.data));
            })
            .catch((e: any) => {
                toast(handleError(e));
                return dispatch(CancelVisitError(e));
            });
    };
};
export default cancelVisit;
