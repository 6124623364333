export const deleteFileStart = (fileIndex: string) => {
  return {
    type: 'DELETE_FILE_STARTED',
    payload: { fileIndex },
  }
}
export const deleteFileSuccess = (file: any, fileIndex: string) => {
  return {
    type: 'DELETE_FILE_SUCCESS',
    payload: { file, fileIndex },
  }
}
export const deleteFileError = (error: any, fileIndex: string) => {
  return {
    type: 'DELETE_FILE_ERROR',
    payload: {
      error,
      fileIndex,
    },
  }
}
