import { createUserStart, createUserSuccess, createUserError } from './types'
import { postData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { getApplicationRole } from '../../../helpers/getApplication'
import { handleError } from '../../../helpers/handleErrors'
import {
  isRoleRenter,
  isRoleOwner,
} from '../../../helpers/roleComparison/index'
const createUser = (
  sessionData: any,
  history: any,
  paramPostId: any,
  shortRegisterRenter = false,
) => {
  return (dispatch: any, getState: any) => {
    const isNewRegister = sessionData.new_register

    dispatch(createUserStart())
    const appRole = getApplicationRole(process.env.REACT_APP_ROLE)

    const data = {
      email: sessionData.email,
      password: sessionData.password,
      repeat_password: sessionData.repeat_password,
      app: appRole,
      referred_code: sessionData.referred_code,
      selected_role: sessionData.selected_role,
      other_selected_role: sessionData.other_selected_role,
      names: sessionData.names,
      surnames: sessionData.surnames,
      phone_number: sessionData.phone_number,
      birth_date: sessionData.birth_date,
      terms_read: sessionData.terms_read,
      looked_post_id: paramPostId,
      token: sessionData.token,
    }

    !paramPostId && delete data.looked_post_id
    const {
      email,
      password,
      repeat_password,
      app,
      referred_code,
      selected_role,
      other_selected_role,
      token,
      ...rest
    } = data

    let userSession = {}
    if (isNewRegister || shortRegisterRenter) {
      userSession = {
        user: {
          email,
          password,
          repeat_password,
          app,
          referred_code,
          selected_role,
          other_selected_role,
          short_register: true,
          token,
        },
      }
    } else {
      userSession = {
        user: {
          ...data,
        },
      }
    }

    postData('auth/signup', userSession)
      .then((result: any) => {
        dispatch(createUserSuccess(result.data))
        if (isRoleOwner || isRoleRenter) {
          localStorage.setItem('emailToken', sessionData.email)
          localStorage.removeItem('referredCode')
          sessionStorage.removeItem('propertyId')
          isNewRegister && localStorage.setItem('isNewRegister', 'yes')
          if (shortRegisterRenter) {
            history.push(`/login?email=${sessionData.email}`)
          }
          else{
            history.push('/thank_you')
          }
          
        } else {
          history.push(`/sign_up/${sessionData.email}`)
        }
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(createUserError(e))
      })
  }
}
export default createUser
