import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { getPostStart, getPostError, getPostSuc, restartDocument } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getUnits = (id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getPostStart());
        getData(`posts/${id}`)
            .then((result) => {
                dispatch(getPostSuc(result.data));
                dispatch(restartDocument());
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getPostError(e));
            });
    };
};
export default getUnits;
