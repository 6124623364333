import { toast } from 'react-toastify'
import { postData } from '../../../tango-react-base/helpers/apiConnections'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { postOwnerPaymentStart, postOwnerPaymentSuccess } from './types'
const addOwnerTransactions = (
  paymentIds: string[],
  countrymanagerName: string,
) => {
  const ids = {
    owner_payments: { ids: paymentIds },
  }

  return (dispatch: any) => {
    dispatch(postOwnerPaymentStart(paymentIds))
    postData('owner_payments/pay', ids)
      .then((result) => {
        return dispatch(
          postOwnerPaymentSuccess(result.data, paymentIds, countrymanagerName),
        )
      })
      .catch((e) => {
        toast(handleError(e))
      })
  }
}
export default addOwnerTransactions
