import {
  setSessionStart,
  setSessionSuccess,
  setSessionSuccessStorage,
  setSessionError,
} from './types'
import { postData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
import { getNewToken } from '../../index'


const setSession = (sessionData: any) => {
  const userSession = {
    user: {
      email: sessionData.email,
      password: sessionData.password,
    },
    role: {
      name: process.env.REACT_APP_ROLE,
    },
  }

  return (dispatch: any) => {
    dispatch(setSessionStart())
    postData('auth/login', userSession)
      .then((result: any) => {
        dispatch(setSessionSuccess(result.data))
        if (sessionData.remember_me.length > 0) {
          localStorage.setItem('authorization', result.headers.authorization)
          localStorage.setItem('session', JSON.stringify(result.data.data))
        }
        // TODO eliminar session storage, porque esto se debe manejar solo con local, revisar cada app para eliminar esto. Hay un problema con guardar la sesion en local storage, y es que la data no se está actualizando cuando el usuario cambia la Identity_document u otro dato, esto puede generar inconsistencias a futuro.
        sessionStorage.setItem('authorization', result.headers.authorization)
        sessionStorage.setItem('session', JSON.stringify(result.data.data))
        dispatch(setSessionSuccessStorage())
      })

      .catch((e) => {
        const errors = { ...e }
        const { response } = errors || ''
        const { data } = response || ''
        const { code } = data?.errors?.[0] || ''
        if (code === 'VERIFY_ACCOUNT_TOKEN') {
          dispatch(getNewToken(sessionData.email))
        }
        toast(handleError(e))
        return dispatch(setSessionError(e))
      })
  }
}
export default setSession
