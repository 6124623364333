import { getBlocksStart, getBlocksSuccess, getBlocksError, getBlocksUnitSuccess, getBlocksUnitError } from './types';
import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { isRoleAdmin, isRoleOwner, isRoleRenter } from '../../helpers/roleComparison/index';
import { isRoleTanguero, isRolePhotographer } from '../../helpers/roleComparison/index';
const moment = require('moment');

const getBlocks = (
    url?: any,
    id?: any,
    history?: any,
    shortRegisterUserId?: boolean,
    visitorType?: string,
    newWantToLease?: boolean,
) => {
    const currentWeek = {
        startDate: moment(),
        endDate: moment().endOf('week'),
    };

    const nextWeek = {
        startDate: moment().add(1, 'weeks').startOf('isoWeek'),
        endDate: moment().add(1, 'weeks').endOf('week'),
    };

    const twoWeeks = [currentWeek, nextWeek];

    const nextTwoWeeks = {
        startDate: moment(),
        endDate: moment().add(1, 'weeks').endOf('week'),
    };

    return (dispatch: any, getState: any) => {
        dispatch(getBlocksStart());
        const baseUrl = 'time_blocks';

        let urlFinal = '';

        if (isRoleOwner) {
            urlFinal = 'user/time_blocks';
        }

        if (isRoleRenter) {
            urlFinal = `user/time_blocks/renter_index`;
        }

        if (!isRoleOwner && !isRoleRenter) {
            urlFinal = 'time_blocks';
        }

        if (
            url !== undefined &&
            process.env.REACT_APP_API_ROLE_APP !== 'BROKER' &&
            process.env.REACT_APP_API_ROLE_APP !== process.env.REACT_APP_VERIFIER
        ) {
            twoWeeks.forEach((week, index) => {
                const filterByDate = `&filters[start_date]=${week.startDate}&filters[end_date]=${week.endDate}`;
                const routeUse = isRoleAdmin
                    ? `users/${id}/${baseUrl}/available_blocks?visitor_type=${visitorType}`
                    : `${urlFinal}${url}${id}${filterByDate}`;
                getData(routeUse)
                    .then((result) => {
                        return dispatch(getBlocksSuccess(result.data, index));
                    })
                    .catch((e) => {
                        toast(handleError(e));
                        return dispatch(getBlocksError(e));
                    });
            });
        } else if (url !== undefined && (isRoleTanguero || isRolePhotographer)) {
            const filterByDate = `&filters[start_date]=${nextTwoWeeks.startDate}&filters[end_date]=${nextTwoWeeks.endDate}`;
            const urls = '/available_blocks?unit_version_id=';
            const routeUse = `${urlFinal}${urls}${id}${filterByDate}`;
            getData(routeUse)
                .then((result) => {
                    return dispatch(getBlocksUnitSuccess(result.data, url));
                })
                .catch((e) => {
                    toast(handleError(e));
                    return dispatch(getBlocksUnitError(e));
                });
        } else {
            getData(urlFinal)
                .then((result) => {
                    return dispatch(getBlocksSuccess(result.data));
                })
                .catch((e) => {
                    toast(handleError(e));
                    return dispatch(getBlocksError(e));
                });
        }
    };
};
export default getBlocks;
