import { setLogOutSuccess, setLogOutError } from './types'
import { deleteData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
import { isRoleRenter } from '../../../helpers/roleComparison/index'

const logOutSession = (history: any) => {
  return (dispatch: any, getState: any) => {
    deleteData('auth/logout')
      .then((result: any) => {
        localStorage.clear()
        sessionStorage.clear()
        dispatch(setLogOutSuccess(result))
        if (isRoleRenter) {
          history.push('/new')
        } else {
          history.push('/login')
        }
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(setLogOutError(e))
      })
  }
}
export default logOutSession
