import getData from './getData';
import deleteData from './deleteData';
import putData from './putData';
import postData from './postData';
import multipartPost from './multipartPost';
import getDataGeneral from './getDataGeneral';
import postDataGeneral from './postDataGeneral';
import putDataGeneral from './putDataGeneral';
import putDataMultipart from './putDataMultipart';
import getDocument from './getDocument';
export {
    getData,
    deleteData,
    putData,
    postData,
    multipartPost,
    getDataGeneral,
    postDataGeneral,
    putDataGeneral,
    putDataMultipart,
    getDocument,
};
