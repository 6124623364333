interface Filter {
  startDate: Date
  endDate: Date
  aasmState: string
  registerType?: string
}

export const buildFiltersTransaction = ({
  startDate,
  endDate,
  aasmState,
  registerType,
}: Filter) => {
  const filters = `?filters[aasm_state]=${aasmState}&filters[start_date]=${startDate}&filters[end_date]=${endDate}&filters[register_type]=${registerType}`
  return filters
}
