import React from 'react'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'

interface CardProps {
  img: string
  title: string
}

const Card = ({ img, title }: CardProps) => {
  let history = useHistory()

  const redirectToView = () => {
    history.push(`/${title.toLowerCase()}`)
  }

  return (
    <>
      <div className='colum-card'>
        <div className='card' onClick={() => redirectToView()}>
          <img src={img} className='card-img-top' alt='CARD' />
          <div className='card-body'>
            <h2 className='card-title'>{intl.get(title)}</h2>
            <div className='template-line-title-card mx-auto' />
            <p>{intl.get(`${title}_TEXT`)}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
