/**
 * @typedef  downloadDirectFileType
 * @type {(string|function)}
 * @property {string} uri - is a string.
 * @property {string} typeFile - is a string.
 * @property {function} func - is a function.
 * @property {string} type - is a string.
 * */

import { isRoleSuperAdmin } from '../roleComparison';

/**
 *  this function transforms the file (base64) that is received from "back" and becomes a downloadable file
 *@function handleDownloadDirectFile
 *@param {downloadDirectFileType}  uri - is the endpoint
 *@param {downloadDirectFileType}  typeFile - type file (pdf,png,xls,etc).
 *@param {downloadDirectFileType}  func - function that executes the obtaining of the file.
 *@param {downloadDirectFileType}  type - target type (_blank|_parent|_self|_top)  .
 * @returns {(file)} Returns a file which can be downloaded
 */
export const handleDownloadDirectFile = async (
    uri: string,
    typeFile: string,
    func: Function,
    type?: string,
    fileName?: string,
) => {
    const { data } = await func(uri);
    let base64String = type ? data.data : data;

    const dataURItoBlob = (dataURI: any) => {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], {
            type: typeFile,
        });
        return blob;
    };

    const blob = dataURItoBlob(base64String);
    let url = URL.createObjectURL(blob);
    let file: any = document.createElement('a');
    if (fileName) {
        file.href = url;
        file.download = fileName;
        file.click();
    } else {
        isRoleSuperAdmin ? window.location.assign(url) : window.open(url, type || '');
    }
};
