import React from 'react';

interface ButtonProps {
    children?: any;
    title_instruction?: string;
    instruction?: string;
    disabled_logo?: boolean;
    grid_body?: number;
    position_body?: string;
    grid_card?: number;
    card?: string;
}

const cardContainer = ({
    children,
    title_instruction,
    instruction,
    disabled_logo,
    grid_body = 8,
    grid_card = 9,
    card = 'card',
    position_body = 'center',
}: ButtonProps) => {
    return (
        <div className='login-form plm-0'>
            <div className={`${card} col-md-${grid_card} plm-0`}>
                {!disabled_logo && (
                    <div className='card-header text-center'>
                        <div className='header-container pt-5 h1'>TANGO</div>
                    </div>
                )}
                <div className='card-body plm-0'>
                    <div className={`d-flex row justify-content-${position_body} plm-0`}>
                        <div className={`col-md-${grid_body} plm-0`}>
                            <div className='text-left pb-3'>
                                <p>{title_instruction}</p>
                                <p>{instruction}</p>
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default cardContainer;
