import tangoBlackLogo from './logos/tangoBlack.png'
import tangoGreyRedLogo from './logos/tangoGreyRed.png'
import tangoRedGreyLogo from './logos/tangoRedGrey.png'
import tangoWhiteGreyLogo from './logos/tangoWhiteGrey.png'
import tangoWhiteRedLogo from './logos/tangoWhiteRed.png'
import tangoBlackRedLogo from './logos/tangoBlackRed.png'

// New Template
// Renter
import renterA854X400 from './template/renter/renter_A_854x400.png'
import renterA1926X600 from './template/renter/renter_A_1926x600.png'
import renterB854X400 from './template/renter/renter_B_854x400.png'
import renterB1926X600 from './template/renter/renter_B_1926x600.png'
import renterC854X400 from './template/renter/renter_C_854x400.png'
import renterC1926X600 from './template/renter/renter_C_1926x600.png'

// Owner
import ownerA854X400 from './template/owner/owner_A_854x400.png'
import ownerA1926X600 from './template/owner/owner_A_1926x600.png'
import ownerB854X400 from './template/owner/owner_B_854x400.png'
import ownerB1926X600 from './template/owner/owner_B_1926x600.png'
import ownerC854X400 from './template/owner/owner_C_854x400.png'
import ownerC1926X600 from './template/owner/owner_C_1926x600.png'
import homeOwner from './images/Header.jpg'
import homeOwnerMobile from './images/HeaderMobile.jpg'
import happyWoman from './images/HappyWoman.png'
import inbox from './icons/inbox.png'
import status from './icons/Status.svg'
import greenCheckIcon from './icons/GreenCheck.svg'
import warningOrangeIcon from './icons/WarningOrangeIcon.svg'
import errorXIcon from './icons/ErrorStatusIcon.svg'

// Country Manager
import ImgCreateProfile from './images/ImgCreateProfile.png'
import ImgAccount from './images/ImgAccount.png'
import ImgPromotions from './images/ImgPromotions.png'
import ImgTransactions from './images/ImgTransactions.png'

// Agent
import anget854x400 from './template/agent/agent_854x400.png'
import anget1926X600 from './template/agent/agent_1926x600.png'

// Admin
import admin854X400 from './template/admin/admin_854x400.png'
import admin1926X600 from './template/admin/admin_1926x600.png'
import AddImage from './template/admin/add_image.png'

// Admin
import verifier854X400 from './template/verifier/verifier_854x600.png'
import verifier1926X600 from './template/verifier/verifier_1926x600.png'
import dragAndDrop from './icons/DragAndDrop.svg'
import uploadLogo from './icons/Upload.svg'
import UploadDisableLogo from './icons/UploadDisableLogo.svg'
import insertedProperties from './icons/InsertedProperties.svg'
import deactiveInsertedProperties from './icons/DeactiveInsertedProperties.svg'
import renterProfile from './icons/RenterProfile.svg'
import ownerProfile from './icons/OwnerProfile.svg'
import verifierProfile from './icons/VerifierProfile.svg'
import agentProfile from './icons/AgentProfile.svg'
import dowloadLogo from './icons/Dowload.svg'
import deleteLogo from './icons/Delete.svg'
import checkLogo from './icons/Check.svg'
import thickCheck from './icons/ThickCheck.svg'
import activeList from './icons/Active_list.png'
import inactiveList from './icons/Inactive_list.png'
import tangoImg from './icons/tangoimg.svg'
import activePin from './icons/Active_pin.png'
import inactivePin from './icons/Inactive_pin.png'
import closeImg from './icons/Close.png'
import favorite from './icons/Favorite.svg'
import bathroom from './icons/Bathroom.svg'
import bedroom from './icons/Bedroom.svg'
import condominium from './icons/Condo.svg'
import furnished from './icons/Furnished.svg'
import innerSurface from './icons/InnerSurface.svg'
import parking from './icons/Parking.svg'
import storage from './icons/Storage.svg'
import terraceSurface from './icons/TerraceSurface.svg'
import totalSurface from './icons/TotalSurface.svg'
import building from './icons/Building.svg'
import floor1 from './icons/Floor1.svg'
import floor2 from './icons/Floor2.svg'
import floor3 from './icons/Floor3.svg'
import floor4 from './icons/Floor4.svg'
import floor5 from './icons/Floor5.svg'
import floor6 from './icons/Floor6.svg'
import floor7 from './icons/Floor7.svg'
import floor8 from './icons/Floor8.svg'
import floor9 from './icons/Floor9.svg'
import floor10 from './icons/Floor10.svg'
import floor11 from './icons/Floor11.svg'
import floor12 from './icons/Floor12.svg'
import floor13 from './icons/Floor13.svg'
import floor14 from './icons/Floor14.svg'
import floor15 from './icons/Floor15.svg'
import floor16 from './icons/Floor16.svg'
import floor17 from './icons/Floor17.svg'
import floor18 from './icons/Floor18.svg'
import floor19 from './icons/Floor19.svg'
import floor20 from './icons/Floor20.svg'
import floor21 from './icons/Floor21.svg'
import floor22 from './icons/Floor22.svg'
import floor23 from './icons/Floor23.svg'
import floor24 from './icons/Floor24.svg'
import floor25 from './icons/Floor25.svg'
import floor26 from './icons/Floor26.svg'
import floor27 from './icons/Floor27.svg'
import floor28 from './icons/Floor28.svg'
import floor29 from './icons/Floor29.svg'
import floor30 from './icons/Floor30.svg'
import countFloor1 from './icons/CountFloor1.svg'
import countFloor2 from './icons/CountFloor2.svg'
import countFloor3 from './icons/CountFloor3.svg'
import orientation from './icons/Orientation.svg'
import balcony from './icons/Balcony.svg'
import terrace from './icons/Terrace.svg'
import outdoorPool from './icons/OutdoorPool.svg'
import livingRoom from './icons/LivingRoom.svg'
import diningRoom from './icons/DiningRoom.svg'
import kitchen from './icons/Kitchen.svg'
import jacuzzi from './icons/Jacuzzi.svg'
import laundryRoom from './icons/LaundryRoom.svg'
import closet from './icons/Closet.svg'
import floorToCeilingHeight from './icons/FloorToCeilingHeight.svg'
import cytophone from './icons/Cytophone.svg'
import alarm from './icons/Alarm.svg'
import thermapanel from './icons/Thermapanel.svg'
import heating from './icons/Heating.svg'
import floorMaterial from './icons/FloorMaterial.svg'
import remodel from './icons/Remodel.svg'
import curtains from './icons/Curtains.svg'
import washing_machine from './icons/WashingMachine.svg'
import dryer from './icons/Dryer.svg'
import refrigerator from './icons/Refrigerator.svg'
import oven from './icons/Oven.svg'
import microwave from './icons/Microwave.svg'
import dishwasher from './icons/Burners.svg'
import burners from './icons/Dishwasher.svg'
import universalAccess from './icons/UniversalAccess.svg'
import elevator from './icons/Elevator.svg'
import constructionYear from './icons/ConstructionYear.svg'
import janitor from './icons/Janitor.svg'
import closedCircuit from './icons/ClosedCircuit.svg'
import visitorParking from './icons/VisitorParking.svg'
import garden from './icons/Garden.svg'
import sauna from './icons/Sauna.svg'
import rooftop from './icons/Rooftop.svg'
import plays from './icons/Plays.svg'
import pets from './icons/Pets.svg'
import multiPurposeRoom from './icons/MultiPurposeRoom.svg'
import indoorPool from './icons/IndoorPool.svg'
import gym from './icons/Gym.svg'
import bicycleRoom from './icons/BicycleRoom.svg'
import barbecue from './icons/Barbecue.svg'
import field from './icons/Field.svg'
import cinema from './icons/Cinema.svg'
import cowork from './icons/Cowork.svg'
import noImg from './icons/NoImg.png'
import house from './icons/House.svg'
import terrainArea from './icons/TerrainArea.svg'
import calendar from './icons/Calendar.svg'
import brokerage from './icons/Brokerage.svg'
import administrationInsurance from './icons/AdministrationInsurance.svg'
import receivedRent from './icons/ReceiveRent.svg'
import row from './icons/Row.png'
import anterior from './icons/Anterior.svg'
import siguiente from './icons/Siguiente.svg'
import step1 from './icons/Step_1.svg'
import step1b from './icons/Step_1b.svg'
import step2 from './icons/Step_2.svg'
import step2b from './icons/Step_2b.svg'
import step3 from './icons/Step_3.svg'
import step3b from './icons/Step_3b.svg'
import step4 from './icons/Step_4.svg'
import step5 from './icons/Step_5.svg'
import arrow1 from './icons/Arrow_1.svg'
import arrow2mobile from './icons/ArrowMobile2.svg'
import arrow2 from './icons/Arrow_2.svg'
import arrow1mobile from './icons/ArrowMobile1.svg'
import arrow3 from './icons/Arrow_3.svg'
import arrow4 from './icons/Arrow_4.svg'
import arrow5 from './icons/Arrow_5.svg'
import arrowRed from './icons/ArrowRed.svg'
import backArrow from './icons/Anterior.svg'
import forwardArrow from './icons/Siguiente.svg'
import map from './icons/Map.svg'
import rentOffer from './icons/RentOffer.svg'
import handshake from './icons/Handshake.svg'
import history from './icons/History.svg'
import thickHistory from './icons/ThickHistory.svg'
import monitor from './icons/Monitor.svg'
import group from './icons/Group.svg'
import home from './icons/Home.svg'
import owner from './icons/Owner.svg'
import renter from './icons/Renter.svg'
import money from './icons/Money.svg'
import fee from './icons/Fee.svg'
import tangobox from './icons/Tangobox.svg'
import process from './icons/Process.svg'
import verification from './icons/Verification.svg'
import codebtor from './icons/Codebtor.svg'
import online from './icons/Online.svg'
import moneyhand from './icons/MoneyHand.svg'
import handproperty from './icons/HandProperty.svg'
import glasshouse from './icons/GlassHouse.svg'
import numberOne from './icons/NumberOne.svg'
import numberTwo from './icons/NumberTwo.svg'
import magentaShoe from './icons/MagentaShoe.svg'
import blackShoe from './icons/BlackShoe.svg'
import userFooter from './icons/UserFooter.svg'
import noteBook from './icons/NoteBook.svg'
import googleMap from './icons/GoogleMaps.svg'
import circlePlus from './icons/CirclePlus.svg'
import dropdown from './icons/Dropdown.svg'
import activePhoto from './icons/Active_photo.svg'
import inactivePhoto from './icons/Inactive_photo.svg'
import activeVideo from './icons/Active_video.svg'
import inactiveVideo from './icons/Inactive_video.svg'
import promise1 from './icons/Promise1.svg'
import promise2 from './icons/Promise2.svg'
import promise3 from './icons/Promise3.svg'
import email from './icons/Email.svg'
import whatsapp from './icons/Whatsapp.svg'
import onlineChat from './icons/OnlineChat.svg'
import phoneCall from './icons/Phone_call.svg'
import homeHeader from './images/homeHeader.webp'
import homeHeaderMobile from './images/homeHeaderMobile.webp'
import homeHeaderMobileSmall from './images/homeHeaderMobileSmall.webp'
import eyeIcon from './icons/EyeIcon.svg'
import checkPopup from './icons/CheckPopup.svg'
import checkSuccess from './icons/CheckSuccess.svg'
import cancelPopup from './icons/CancelPopup.svg'
import MailConfirmation from './icons/MailConfirmation.svg'
import promotion from './icons/Promotion.svg'
import sendIcon from './icons/SendIcon.svg'
import idIcon from './icons/IDIcon.svg'
import sendProfile from './icons/SendProfile.svg'
import waterHeating from './icons/WaterHeating.svg'
import newsIcon from './icons/NewsIcon.svg'
import infoIcon from './icons/infoIcon.svg'

import SignupDeskRegistroRenter from './icons/SignupDeskRegistroRenter.svg'
import SingupDeskRegistro2Renter from './icons/SingupDeskRegistro2Renter.svg'
import SingupDeskLayerRenter from './icons/SingupDeskLayerRenter.svg'
import SingupDeskRenter from './icons/SingupDeskRenter.svg'
import SignupMobRegistroRenter from './icons/SignupMobRegistroRenter.svg'
import SingupMobRegistro2Renter from './icons/SingupMobRegistro2Renter.svg'
import SingupMobLayerRenter from './icons/SingupMobLayerRenter.svg'
import SingupMobRenter from './icons/SingupMobRenter.svg'
import SignupDeskCorretajeOwner from './icons/SignupDeskCorretajeOwner.svg'
import SignupDeskOfertaOwner from './icons/SignupDeskOfertaOwner.svg'
import SignupDeskPagosOwner from './icons/SignupDeskPagosOwner.svg'
import SignupDeskOwner from './icons/SignupDeskOwner.svg'
import SignupMobCorretajeOwner from './icons/SignupMobCorretajeOwner.svg'
import SignupMobOfertaOwner from './icons/SignupMobOfertaOwner.svg'
import SignupMobPagosOwner from './icons/SignupMobPagosOwner.svg'
import SignupMobOwner from './icons/SignupMobOwner.svg'
import tangoLogo from './logos/tangoLogo.png'
import facebook from './icons/Facebook.svg'
import telegram from './icons/Telegram.svg'
import twitter from './icons/Twitter.svg'
import instagram from './icons/Telegram.svg'
import messenger from './icons/Messenger.svg'
import mail from './icons/Mail.svg'
import linkedIn from './icons/LinkedIn.svg'
import whatsappShare from './icons/WhatsappShare.svg'
import danger from './icons/Danger.svg'
import iconFloatSave from './icons/IconFloatSave.svg'
import leaseRenewalIcon from './icons/LeaseRenewal.svg'
import noLeaseRenewalIcon from './icons/NoLeaseRenewal.svg'
import inventoryIcon from './icons/Inventory.svg'
import mediationIcon from './icons/Mediation.svg'
import contractIcon from './icons/Contract.svg'
import greenCircleCheck from './icons/Circle Check.svg'
import alertInfoIcon from './icons/AlertInfoIcon.svg'
import newPriceIcon from './icons/NewPrice.svg'
import NoRenewalIcon from './icons/NoRenewal.svg'
import clock from './icons/Clock.svg'
import clockHistory from './icons/ClockHistory.svg'
import rejectIcon from './icons/RejectIcon.svg'
import leaseOffer from './icons/LeaseOffer.svg'
import footerIgIcon from './icons/FooterIgIcon.svg'
import footerLinkedinIcon from './icons/FooterLinkedinIcon.svg'
import footerFbIcon from './icons/FooterFbIcon.svg'
import closingModalIcon from './icons/ClosingModalIcon.svg'
import servipagLogo from './logos/servipag.png'
import padpowLogo from './logos/padpow.svg'
import neatLogo from './logos/neat.svg'
import neatLogoDisabled from './logos/neat_disabled.svg'
import fintocLogo from './logos/fintoc.svg'
import soloCorretaje from './icons/SoloCorretaje.svg'
import soloAdministracion from './icons/SoloAdministracion.svg'
import planFull from './icons/PlanFull.svg'
import blackFb from './icons/BlackFb.svg'
import blackLinkedin from './icons/BlackLinkedin.svg'
import blackIg from './icons/BlackIg.svg'
import tangoHomeIcon from './icons/TangoHomeIcon.svg'
import tangoKeyIcon from './icons/TangoKeyIcon.svg'
import referredIcon from './icons/Referred.svg'
import closeTooltip from './icons/CloseTooltip.svg'
import twoPersonContract from './icons/TwoPersonContract.svg'

// Tango Home
import HappyManHome1 from './icons/HappyManHome_1.png'
import HappyManHome2 from './icons/HappyManHome_2.png'
import BgMapHome from './icons/BgMapHome.png'
import BlackCheck from './icons/BlackCheck.svg'
import StarFilled from './icons/StarFilled.png'
import GreystarLogo from './icons/GreystarLogo.png'
import ActivaLogo from './icons/ActivaLogo.png'
import IcuadraLogo from './icons/IcuadraLogo.png'
import IandesLogo from './icons/IandesLogo.png'
import LuniaLogo from './icons/LuniaLogo.png'
import PatioLogo from './icons/PatioLogo.png'
import BravoLogo from './icons/BravoLogo.png'
import RedChellphone from './icons/RedCellphone.png'
import RedChat from './icons/RedChat.png'
import RedWhatsapp from './icons/RedWhatsapp.png'
import RedEmail from './icons/RedEmail.png'
import SignupDeskRegistroRenterTango from './icons/SignupDeskRegistroRenterTango.svg'
import SingupDeskRegistro2RenterTango from './icons/SingupDeskRegistro2RenterTango.svg'
import SingupDeskLayerRenterTango from './icons/SingupDeskLayerRenterTango.svg'
import SingupDeskRenterTango from './icons/SingupDeskRenterTango.svg'
import PhoneContact from './icons/PhoneContact.svg'
import AddNewImage from './images/AddNewImage.png'

// Tanguero
import CalendarDate from './icons/CalendarDate.svg'
import CalendarOff from './icons/CalendarOff.svg'

import HappyManTango from './images/HappyManTango.png'
import CloseBtnX from './icons/CloseBtnX.svg'
// Flags
import ChileFlag from './flags/ChileFlag.png'

// Home Images
import OwnerHomeDesktop from './images/OwnerHomeDesktop.png'
import RenterHomeDesktop from './images/RenterHomeDesktop.png'
import OwnerHomeMobile from './images/OwnerHomeMobile.png'
import RenterHomeMobile from './images/RenterHomeMobile.png'
import OwnerHomeTablet from './images/OwnerHomeTablet.png'
import RenterHomeTablet from './images/RenterHomeTablet.png'

// Zona propia
import zonaPropiaDesktop from './images/zona_propia_desktop.svg'
import zonaPropiaMobile from './images/zona_propia_mobile.svg'
import zonaPropiaTablet from './images/zona_propia_tablet.svg'

// Codigos de error
import codeFourZeroFour from './icons/404.svg'
import timeoutComplexIcon from './icons/Timeout.svg'

// Signature Initiation Images
import signatureInitiation from './icons/SignatureInitiation.svg'


const white = '#FFFFFF'
const primary = '#1A1A1A'
const secondary = '#a8123e'
const tertiary = '#999999'
const fourth = '#333333'
const fifth = '#F7F7F7'

export {
  iconFloatSave,
  SignupMobCorretajeOwner,
  SignupMobOfertaOwner,
  SignupMobPagosOwner,
  SignupMobOwner,
  SignupDeskCorretajeOwner,
  SignupDeskOfertaOwner,
  SignupDeskPagosOwner,
  SignupDeskOwner,
  SignupMobRegistroRenter,
  SingupMobRegistro2Renter,
  SingupMobLayerRenter,
  SingupMobRenter,
  SignupDeskRegistroRenter,
  SignupDeskRegistroRenterTango,
  SingupDeskRegistro2Renter,
  SingupDeskRegistro2RenterTango,
  SingupDeskLayerRenter,
  SingupDeskLayerRenterTango,
  SingupDeskRenter,
  SingupDeskRenterTango,
  tangoBlackLogo,
  tangoBlackRedLogo,
  tangoGreyRedLogo,
  tangoRedGreyLogo,
  tangoWhiteGreyLogo,
  tangoWhiteRedLogo,
  administrationInsurance,
  brokerage,
  receivedRent,
  uploadLogo,
  white,
  primary,
  secondary,
  tertiary,
  fourth,
  fifth,
  deleteLogo,
  dowloadLogo,
  checkLogo,
  thickCheck,
  activeList,
  inactiveList,
  activePin,
  inactivePin,
  closeImg,
  favorite,
  bathroom,
  bedroom,
  condominium,
  furnished,
  innerSurface,
  parking,
  storage,
  terraceSurface,
  totalSurface,
  building,
  floor1,
  floor2,
  floor3,
  floor4,
  floor5,
  floor6,
  floor7,
  floor8,
  floor9,
  floor10,
  floor11,
  floor12,
  floor13,
  floor14,
  floor15,
  floor16,
  floor17,
  floor18,
  floor19,
  floor20,
  floor21,
  floor22,
  floor23,
  floor24,
  floor25,
  floor26,
  floor27,
  floor28,
  floor29,
  floor30,
  countFloor1,
  countFloor2,
  countFloor3,
  orientation,
  balcony,
  terrace,
  outdoorPool,
  livingRoom,
  diningRoom,
  jacuzzi,
  kitchen,
  laundryRoom,
  closet,
  floorToCeilingHeight,
  cytophone,
  alarm,
  thermapanel,
  heating,
  floorMaterial,
  remodel,
  curtains,
  washing_machine,
  dryer,
  refrigerator,
  oven,
  microwave,
  dishwasher,
  burners,
  universalAccess,
  elevator,
  closedCircuit,
  janitor,
  constructionYear,
  calendar,
  visitorParking,
  garden,
  sauna,
  rooftop,
  plays,
  pets,
  multiPurposeRoom,
  indoorPool,
  gym,
  bicycleRoom,
  barbecue,
  field,
  cinema,
  cowork,
  noImg,
  row,
  house,
  terrainArea,
  anterior,
  siguiente,
  step1,
  step1b,
  step2,
  step2b,
  step3,
  step3b,
  step4,
  step5,
  arrow1,
  arrow2,
  arrow3,
  arrow4,
  arrow5,
  arrowRed,
  backArrow,
  arrow1mobile,
  arrow2mobile,
  forwardArrow,
  map,
  rentOffer,
  handshake,
  history,
  thickHistory,
  monitor,
  group,
  home,
  owner,
  tangoImg,
  renter,
  money,
  fee,
  tangobox,
  process,
  verification,
  codebtor,
  online,
  moneyhand,
  glasshouse,
  handproperty,
  dropdown,
  activePhoto,
  inactivePhoto,
  activeVideo,
  inactiveVideo,
  promise1,
  promise2,
  promise3,
  phoneCall,
  eyeIcon,
  infoIcon,
  checkPopup,
  checkSuccess,
  cancelPopup,
  facebook,
  telegram,
  twitter,
  instagram,
  messenger,
  mail,
  linkedIn,
  whatsappShare,
  danger,
  promotion,
  sendIcon,
  idIcon,
  sendProfile,
  waterHeating,
  newsIcon,
  blackFb,
  blackLinkedin,
  blackIg,
  tangoHomeIcon,
  tangoKeyIcon,
  // Renter
  renterA854X400,
  renterA1926X600,
  renterB854X400,
  renterB1926X600,
  renterC854X400,
  renterC1926X600,
  // Owner
  ownerA854X400,
  ownerA1926X600,
  ownerB854X400,
  ownerB1926X600,
  ownerC854X400,
  ownerC1926X600,
  numberOne,
  numberTwo,
  magentaShoe,
  blackShoe,
  userFooter,
  noteBook,
  googleMap,
  circlePlus,
  homeOwner,
  homeOwnerMobile,
  happyWoman,
  email,
  whatsapp,
  onlineChat,
  homeHeader,
  homeHeaderMobile,
  homeHeaderMobileSmall,
  tangoLogo,
  soloCorretaje,
  soloAdministracion,
  planFull,
  inbox,
  referredIcon,
  closeTooltip,
  // Agent
  anget854x400,
  anget1926X600,
  CalendarDate,
  CalendarOff,
  // Admin
  admin854X400,
  admin1926X600,
  insertedProperties,
  deactiveInsertedProperties,
  // Verifier
  verifier854X400,
  verifier1926X600,
  // Country
  ImgCreateProfile,
  ImgAccount,
  ImgPromotions,
  ImgTransactions,
  MailConfirmation,
  // ICONS
  renterProfile,
  ownerProfile,
  verifierProfile,
  agentProfile,
  // Contract
  leaseRenewalIcon,
  noLeaseRenewalIcon,
  inventoryIcon,
  mediationIcon,
  contractIcon,
  alertInfoIcon,
  newPriceIcon,
  NoRenewalIcon,
  clock,
  clockHistory,
  rejectIcon,
  leaseOffer,
  footerIgIcon,
  footerLinkedinIcon,
  footerFbIcon,
  closingModalIcon,
  servipagLogo,
  padpowLogo,
  neatLogo,
  neatLogoDisabled,
  fintocLogo,
  //Home
  HappyManHome1,
  HappyManHome2,
  BgMapHome,
  BlackCheck,
  StarFilled,
  GreystarLogo,
  ActivaLogo,
  IcuadraLogo,
  LuniaLogo,
  IandesLogo,
  RedChellphone,
  RedChat,
  RedEmail,
  RedWhatsapp,
  HappyManTango,
  PhoneContact,
  CloseBtnX,
  ChileFlag,
  AddNewImage,
  AddImage,
  OwnerHomeDesktop,
  RenterHomeDesktop,
  OwnerHomeMobile,
  RenterHomeMobile,
  OwnerHomeTablet,
  RenterHomeTablet,
  zonaPropiaDesktop,
  zonaPropiaMobile,
  zonaPropiaTablet,
  dragAndDrop,
  UploadDisableLogo,
  BravoLogo,
  PatioLogo,
  status,
  codeFourZeroFour,
  greenCheckIcon,
  warningOrangeIcon,
  errorXIcon,
  timeoutComplexIcon,
  greenCircleCheck,
  twoPersonContract,
  signatureInitiation
}
