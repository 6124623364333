export const setSessionStart = () => {
  return {
    type: 'SET_USER_DATA_STARTED',
  }
}

export const setSessionSuccess = (session: any) => {
  return {
    type: 'SET_USER_DATA_SUCCESS',
    payload: { session },
  }
}

export const setSessionSuccessStorage = () => {
  return {
    type: 'SET_USER_DATA_SUCCESS_STORAGE'
  }
}

export const setSessionError = (error: any) => {
  return {
    type: 'SET_USER_DATA_ERROR',
    payload: {
      error,
    },
  }
}
