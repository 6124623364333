import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { SuperAdminReduxState } from '../../tango'
import intl from 'react-intl-universal'
import Card from '../../components/card'
import {
  ImgCreateProfile,
  ImgAccount,
  ImgPromotions,
  ImgTransactions,
} from '../../tango-react-base/resources'

/**
 * Here it is defined the type of the Home Component , this prop is similar to 'HomeProps' but 'HomePropsTypes' is for the documentation
 * @typedef HomePropsTypes
 * @type {(function|string)}
 * @property {function} onSetTemplateChild - is a function.
 * @property {string} sessionStatus - is an string.
 * @property {string} userRoles - is an string.
 */
type HomeProps = {
  onSetTemplateChild: (child: Object) => void
  sessionStatus: string
  userRoles?: string
}

/**
 * Home is a functional component
 *@function
 * @param {HomePropsTypes} onSetTemplateChild - this function is a simple template
 * @param {HomePropsTypes} sessionStatus - this attribute to know the state of the session
 * @param {HomePropsTypes} userRoles -  this attribute to know the user app
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const Home = ({ onSetTemplateChild, sessionStatus, userRoles }: HomeProps) => {
  const history = useHistory()

  const isUserExpired =
    history.location.pathname === '/' &&
    sessionStatus === 'NO_FETCH' &&
    !userRoles?.includes('master_admin')

  useEffect(() => {
    if (isUserExpired) {
      localStorage.clear()
      sessionStorage.clear()
      return history.push('/login')
    } else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('COUNTRY_MANAGER')}</h1>
        </>,
      )
    }
    // eslint-disable-next-line
  }, [sessionStatus, onSetTemplateChild, isUserExpired])

  const HeaderHome = () => {
    return (
      <>
        <div className='template-line-title' />
        <div>
          <h2 className='text my-2'>{intl.get('MENU')}</h2>
        </div>
      </>
    )
  }

  return (
    <div className='container-home pd-1 mt-5'>
      <HeaderHome />
      <div className='card-country-manager'>
        <Card img={ImgTransactions} title='TRANSACTIONS' />
        <Card img={ImgPromotions} title='PROMOTIONS' />
        <Card img={ImgCreateProfile} title='CREATE_USER' />
      </div>
      <div className='card-country-manager'>
        <Card img={ImgAccount} title='MY_ACCOUNT' />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      return dispatch(setTemplateChild(child))
    },
  }
}
const mapStateToProps = (state: SuperAdminReduxState) => {
  console.log(state.session.user?.attributes);
  
  return {
    sessionStatus: state.session.fetchSessionStatus,
    userRoles: state.session.user?.attributes?.user_roles,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
