import { approveDocumentStart, approveDocumentSuc } from './types';

const getUnit = (property_comments: string) => {
    return (dispatch: any) => {
        dispatch(approveDocumentStart());
        dispatch(approveDocumentSuc(property_comments));
    };
};

export default getUnit;
