export const createUserStart = () => {
  return {
    type: 'CREATE_USER_STARTED',
  }
}

export const createUserSuccess = (data: any) => {
  return {
    type: 'CREATE_USER_SUCCESS',
    payload: {
      data,
      error: '',
    },
  }
}

export const createUserError = (error: any) => {
  return {
    type: 'CREATE_USER_ERROR',
    payload: {
      error,
    },
  }
}
