import axios from 'axios';
export default async (url: string, data: any) => {
    let api_url = `${process.env.REACT_APP_API_URL}`;
    let role = `${process.env.REACT_APP_API_ROLE_APP}`;
    const uri =
        url.split('/')[0] === 'auth' || url.split('/')[0] === 'user'
            ? api_url.replace(role.toLowerCase(), `${url}`)
            : `${process.env.REACT_APP_API_URL}/${url}`;
    const headers: any = {
        'content-type': 'multipart/form-data',
        authorization: sessionStorage.getItem('authorization'),
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
    };
    return axios.post(uri, data, { headers, maxBodyLength: Infinity, maxContentLength: Infinity });
};
