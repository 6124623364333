import createAdmin from './createAdmin'
import deleteFile from './deleteFile'
import uploadFile from './uploadFile'
import getPromotion from './getPromotion'
import getPromotions from './getPromotions'
import createPromotion from './createPromotion'
import updatePromotion from './updatePromotion'
import addOwnerTransactions from './postTransactions/postOwnerPayment'
import addReferencecsTransactions from './postTransactions/postReferencesPayment'
import putOwnerTransactions from './putTransactions/putOwnerPayment'
import putReferencesTransactions from './putTransactions/putReferencesPayment'
import getRestitutions from './getRestitutions'
import finishPromotion from './finishPromotion'

export {
  createAdmin,
  uploadFile,
  deleteFile,
  getPromotion,
  getPromotions,
  createPromotion,
  updatePromotion,
  addOwnerTransactions,
  addReferencecsTransactions,
  putOwnerTransactions,
  putReferencesTransactions,
  getRestitutions,
  finishPromotion
}
