import { sendTokeStart, sendTokeSuccess, sendTokeError } from './types'
import { postData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { getApplicationRole } from '../../../helpers/getApplication'
import { handleError } from '../../../helpers/handleErrors'
const setTokenNewPassword = (sessionData: any, history: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(sendTokeStart())
    const appRole = getApplicationRole(process.env.REACT_APP_ROLE)
    let userSession = {
      user: {
        email: sessionData.email,
        token: sessionData.token,
        app: appRole,
      },
    }
    postData('auth/users/validate_reset_password_token', userSession)
      .then((result: any) => {
        dispatch(sendTokeSuccess())
        history.push(
          `/forget_password/${sessionData.email}/token/${sessionData.token}`,
        )
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(sendTokeError(e))
      })
  }
}
export default setTokenNewPassword
