import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import '../../App.scss'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import {
  ButtonsHeader,
  CircularLoading,
  CardPromotions,
} from '../../tango-react-base/components'
import { getPromotions } from '../../redux/actions'
import { redirect_tabs } from '../buttonsOptions'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize'
import finishPromotion from '../../redux/actions/finishPromotion'

/**
 * Here it is defined 'PromotionsPropsTypes' this is the list of promotions created
 * @typedef PromotionsPropsTypes
 * @type {(function|string|Array)}
 * @property {function} onGetPromotions - is an function.
 * @property {Array} promotions - is a Array.
 * @property {string} fetchPromotions - is a string.
 * @property {function} onSetTemplateChild - is a function.
 */
interface PromotionsProps {
  onSetTemplateChild: (child: Object) => void
  onGetPromotions: () => void
  onFinishPromotion: (id: string) => void
  promotions: any
  fetchPromotions: string
}

const Promotions = ({
  onSetTemplateChild,
  onGetPromotions,
  onFinishPromotion,
  promotions,
  fetchPromotions,
}: PromotionsProps) => {
  const history = useHistory()
  const size = useWindowSize()

  const [stateFinishPromotion, setStateFinishPromotion] = useState({
    openModal: false,
    id: '',
  }) 

  useEffect(() => {
    onGetPromotions()
  }, [onGetPromotions])

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('PROMOTIONS')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'promotions'}
          />
        </>,
      )
    }
  }, [onSetTemplateChild])


  const HeaderPromotionList = () => {
    return (
      <>
        <div className='col-sm-8 p-0 my-2'>
          <div className='template-line-title' />
          <div
            className={`d-flex justify-content-between ${
              size.width <= 768 && 'flex-column'
            }`}
          >
            <h2 className='text'>{intl.get('PROMOTION_LIST')}</h2>
          </div>
        </div>
        <div className='col-sm-4 p-0 my-2'>
          <button
            className='col-12  button-fourth'
            onClick={(e: any) => history.push(`promotion/new`)}
          >
            {intl.get('CREATE_PROMOTION')}
          </button>
        </div>
      </>
    )
  }
  return (
    <div className='container-fluid promotions-card-component'>
      <div className='container-home pd-1 home-renter-container my-4'>
        <div className='container-fluid'>
          <div className='row'>
            <HeaderPromotionList />
          </div>
        </div>
      </div>
      <div className='container-home pd-1 home-renter-container my-4'>
        {fetchPromotions === 'FETCHING_PROMOTIONS' && <CircularLoading />}
        {fetchPromotions === 'FETCHED_PROMOTIONS' && 
          <CardPromotions promotions={promotions} 
                          setStateFinishPromotion={setStateFinishPromotion}
                          stateFinishPromotion={stateFinishPromotion}
                          onCallback={onFinishPromotion}
                          /> 
          }
        {fetchPromotions === 'ERROR_FETCHING_PROMOTIONS' && intl.get('ERROR')}
      </div>
    </div>
  )
}
/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  mapDispatchToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {mapDispatchToProps}  state - here the redux data is obtained.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetPromotions: () => {
      dispatch(getPromotions())
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onFinishPromotion: (id: string) => {
      dispatch(finishPromotion(id))
    }
  }
}
/**
 *@function
 *@param {mapStateToProps}  dispatch - Dispatch an action to change the state.
 */
const mapStateToProps = (state: any) => {
  return {
    fetchPromotions: state.promotions.fetchPromotions,
    promotions: state.promotions.promotions,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Promotions)
