export const getRestitutionsStart = (state_delivery_voucher: string) => {
  if (state_delivery_voucher === 'pending') {
    return {
      type: 'GET_RESTITUTIONS_PENDING_STARTED',
    }
  } else {
    return {
      type: 'GET_RESTITUTIONS_STARTED',
    }
  }
}

export const getRestitutionsSuccess = (state_delivery_voucher: string, result: any) => {
  if (state_delivery_voucher === 'pending') {
    return {
      type: 'GET_RESTITUTIONS_PENDING_SUCCESS',
      payload: result
    }
  } else {
    return {
      type: 'GET_RESTITUTIONS_SUCCESS',
      payload: result
    }
  }
}

export const getRestitutionsError = (state_delivery_voucher: string, error: any) => {
  if (state_delivery_voucher === 'pending') {
    return {
      type: 'GET_RESTITUTIONS_PENDING_ERROR',
      payload: {
        error,
      },
    }
  } else {
    return {
      type: 'GET_RESTITUTIONS_ERROR',
      payload: {
        error
      }
    }
  }
}
