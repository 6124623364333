import {
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordError,
} from './types'
import { putData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { getApplicationRole } from '../../../helpers/getApplication'
import { handleError } from '../../../helpers/handleErrors'
const updatePassword = (sessionData: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(updatePasswordStart())
    const appRole = getApplicationRole(process.env.REACT_APP_ROLE)
    let userSession = {
      user: {
        email: sessionData.email,
        password: sessionData.password,
        repeat_password: sessionData.repeat_password,
        names: sessionData.names,
        surnames: sessionData.surnames,
        first_surname: sessionData.first_surname,
        second_surname: sessionData.second_surname,
        phone_number: sessionData.phone_number,
        birth_date: sessionData.birth_date,
        rut: sessionData.rut,
        terms_read: sessionData.terms_read,
        token: sessionData.token,
        app: appRole,
      },
    }
    putData('auth/users/new_password', userSession)
      .then((result: any) => {
        dispatch(updatePasswordSuccess())
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(updatePasswordError(e))
      })
  }
}
export default updatePassword
