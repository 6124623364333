import { putData } from '../../../tango-react-base/helpers/apiConnections'
import {
  putReferencesPaymentStart,
  putReferencesPaymentSuccess,
} from './types'
const putReferencesTransactions = (id: string, countrymanagerName: string) => {
  return (dispatch: any) => {
    dispatch(putReferencesPaymentStart(id))
    putData(`reference_lines/${id}/unpay`)
      .then((result) => {
        return dispatch(putReferencesPaymentSuccess(result.data, id, countrymanagerName))
      })
  }
}
export default putReferencesTransactions
