import { deleteData } from '../../../tango-react-base/helpers/apiConnections'
import { deleteFileStart, deleteFileSuccess, deleteFileError } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const deleteFile = (url: string, id: string, fileIndex: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(deleteFileStart(fileIndex))
    deleteData(`${url}/${id}`)
      .then((result) => {
        toast('File deleted.')
        dispatch(deleteFileSuccess(result.data, fileIndex))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(deleteFileError(e, fileIndex))
      })
  }
}

export default deleteFile
