import {
  sendUserTokeStart,
  sendUserTokeSuccess,
  sendUserTokeError,
} from './types'
import { postData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { getApplicationRole } from '../../../helpers/getApplication'
import { handleError } from '../../../helpers/handleErrors'
const setTokenNewPassword = (sessionData: any, history?: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(sendUserTokeStart())
    const appRole = getApplicationRole(process.env.REACT_APP_ROLE)
    let userSession = {
      user: {
        email: sessionData.email,
        token: sessionData.token,
        app: appRole,
      },
    }
    postData('auth/users/validate_verify_account_token', userSession)
      .then((result: any) => {
        dispatch(sendUserTokeSuccess())
        toast(intl.get('USER_VERIFIED'))
        localStorage.removeItem('isNewRegister')
        history.push(`/login`)
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(sendUserTokeError(e))
      })
  }
}
export default setTokenNewPassword
