import axios from 'axios';

export default async (url: string, header?: any) => {
    let api_url = `${process.env.REACT_APP_API_URL}`;
    let role = `${process.env.REACT_APP_API_ROLE_APP}`;
    const uri =
        url.split('/')[0] === 'auth'
            ? api_url.replace(role.toLowerCase(), `${url}`)
            : `${process.env.REACT_APP_API_URL}/${url}`;
    let headers = {
        Authorization: sessionStorage.getItem('authorization'),
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
        ...header,
    };
    return axios.delete(uri, { headers });
};
