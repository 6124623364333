export const getPostStart = () => {
    return {
        type: 'GET_POST_STARTED',
    };
};

export const getPostSuc = (post: any) => {
    return {
        type: 'GET_POST_SUCCESS',
        payload: post,
    };
};

export const getPostError = (error: any) => {
    return {
        type: 'GET_POST_ERROR',
        payload: {
            error,
        },
    };
};
export const restartDocument = () => {
    return {
        type: 'RESET_DOCUMENT',
    };
};
