import React from 'react';
import intl from 'react-intl-universal';
import DialogModal from '../dialogModal'

/**
 * Here the type of each Cardpromotion for the documentation
 * @typedef CardPromotionsProps
 * @type {(function|string|any)}
 * @property {any} promotions - is a array with promotions.
 * @property {string} divClassTitle - is a class String optional for title .
 * @property {string} divClassData - is a class String optional for content.
 * @property {string} divClass - is a class String for the card.
 * @property {string} marginDesktop -is class String for margin
 * @property {string} divLong -is a class String for divs
 */
interface CardPromotionsProps {
    promotions: any;
    divClassTitle?: string;
    divClassData?: string;
    divClass?: string;
    marginDesktop?: string;
    divLong?: string;
    setStateFinishPromotion?: any;
    stateFinishPromotion?: any;
    onCallback?: any;
}

/**
 * Here the type of each CardPlanDetail for the documentation
 * @typedef CardPlanDetailsProps
 * @type {(function|string|any)}
 * @property {string}  kind- is a array with promotions.
 * @property {boolean} active - is a String.
 * @property {string} possibleDiscounts - is a String.
 * @property {any} duration - is a String.
 * @property {any} discountList -is String
 */
interface CardPlanDetailsProps {
    kind: string;
    active: boolean;
    possibleDiscounts: string[];
    duration?: any;
    discountList: { name: string; value: number }[];
}

interface ShowModalProps {
    setStateFinishPromotion?: any;
    stateFinishPromotion?: any;
    onCallback?: any;
}

/**
 * Promotions component functions
 * @param CardPlanDetailsProps
 * @returns jsx.function
 * @describe show the plan details for a promotions
 */
const CardPlanDetails = ({ kind, active, possibleDiscounts, duration, discountList = [] }: CardPlanDetailsProps) => {
    return active ? (
        <div className='card-body promotion-card-plan-detail'>
            <div className='promotion-card-plan-section'>
                <div className='row'>
                    <section>
                        <dl className=''>
                            <dt className='plan-title'>{intl.get('PLAN')}</dt>
                            <dd className='plan-name'>{intl.get(kind)}</dd>
                        </dl>
                    </section>
                    <section>
                        <dl className=''>
                            {duration && (
                                <>
                                    <dt className='plan-duration'>{intl.get('DURATION')}</dt>
                                    <dd className='plan-duration'>
                                        {duration} {intl.get('MONTHS')}
                                    </dd>
                                </>
                            )}
                        </dl>
                    </section>
                </div>
                <div className='row'>
                    <section>
                        <dl className='discount-list'>
                            {discountList.map((el) => {
                                return (
                                    el.value &&
                                    possibleDiscounts.includes(el.name) && (
                                        <>
                                            <dt>{intl.get(el.name)}</dt>
                                            <dd>{el.value} %</dd>
                                        </>
                                    )
                                );
                            })}
                        </dl>
                    </section>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

/**
 * function ShowModal show a modal to confirm the finish promotion
 */
const ShowModal = ({
    setStateFinishPromotion,
    stateFinishPromotion,
    onCallback
    }: ShowModalProps) => {
    return (
    <DialogModal
        title={intl.get('FINISH_PROMOTION_CONFIRMATION')}
        open={stateFinishPromotion.openModal}
        handleClose={() => setStateFinishPromotion({ ...stateFinishPromotion, id: '', openModal: false })}
        firstPosition
    >
        <button
        className='button-primary col-md-4 col-5 mr-2'
        onClick={() => onCallback(stateFinishPromotion.id)}
        >
        {intl.get('CONFIRM_BTN')}
        </button>
        <button
        className='button-primary col-md-4 col-5'
        onClick={() => setStateFinishPromotion({ ...stateFinishPromotion, id: '', openModal: false })}
        >
        {intl.get('CANCEL')}
        </button>
    </DialogModal>
    )
}

/**
 * CardPromotions component functions
 * @param CardPromotionsProps
 * @returns jsx.function
 * @describe show a card with promotions
 */
const CardPromotions = ({
    promotions,
    setStateFinishPromotion,
    stateFinishPromotion,
    onCallback
    }: CardPromotionsProps) => {

    console.warn('Card Promotion is deprecated');

    const confirmFinishPromotion = (id: string) => {
        setStateFinishPromotion({ openModal: true, id })
    }

    return (
        <>
            {promotions.map((promotion: any) => {
                const id = promotion.id;
                const {
                    name,
                    description,
                    kind,
                    address,
                    starts_at,
                    ends_at,
                    aasm_state,
                    state_to_show,
                } = promotion.attributes;
                const {
                    administration_fee_new_discount,
                    new_discount_duration,
                    collection_fee_new_discount_owner,
                    collection_fee_new_discount_renter
                } = promotion.attributes.new_unit;
                const {
                    collection_fee_brokerage_discount_owner,
                    collection_fee_brokerage_discount_renter,
                } = promotion.attributes.brokerage_unit;
                const {
                    administration_fee_registered_discount,
                    registered_discount_duration,
                } = promotion.attributes.registered_unit;

                const planFullTango =
                    (administration_fee_new_discount && new_discount_duration) ||
                    collection_fee_new_discount_owner ||
                    collection_fee_new_discount_renter;

                const planBrokerage =
                    collection_fee_brokerage_discount_owner || collection_fee_brokerage_discount_renter;

                const planAdministered = administration_fee_registered_discount && registered_discount_duration;

                const hasPlan = planFullTango || planBrokerage || planAdministered;

                const discountList = [
                    { name: 'ADMINISTRATION_FEE_NEW_DISCOUNT', value: administration_fee_new_discount },
                    { name: 'ADMINISTRATION_FEE_REGISTERED_DISCOUNT', value: administration_fee_registered_discount },
                    { name: 'OWNER_BROKERAGE_FEE_NEW_DISCOUNT', value: collection_fee_new_discount_owner },
                    { name: 'RENTER_BROKERAGE_FEE_NEW_DISCOUNT', value: collection_fee_new_discount_renter },
                    { name: 'OWNER_BROKERAGE_FEE_BROKERAGE_DISCOUNT', value: collection_fee_brokerage_discount_owner },
                    {
                        name: 'RENTER_BROKERAGE_FEE_BROKERAGE_DISCOUNT',
                        value: collection_fee_brokerage_discount_renter,
                    },
                ];

                const plans = [
                    {
                        kind: 'PLAN_FULL',
                        active: planFullTango,
                        possibleDiscounts: [
                            'ADMINISTRATION_FEE_NEW_DISCOUNT',
                            'OWNER_BROKERAGE_FEE_NEW_DISCOUNT',
                            'RENTER_BROKERAGE_FEE_NEW_DISCOUNT',
                        ],
                        duration: new_discount_duration,
                        discountList,
                    },
                    {
                        kind: 'BROKERAGE',
                        active: planBrokerage,
                        possibleDiscounts: [
                            'OWNER_BROKERAGE_FEE_BROKERAGE_DISCOUNT',
                            'RENTER_BROKERAGE_FEE_BROKERAGE_DISCOUNT',
                        ],
                        duration: null,
                        discountList,
                    },
                    {
                        kind: 'ADMINISTERED',
                        active: planAdministered,
                        possibleDiscounts: ['ADMINISTRATION_FEE_REGISTERED_DISCOUNT'],
                        duration: registered_discount_duration,
                        discountList,
                    },
                ];

                return (
                    <div key={promotion.id} className='promotion-card'>
                        <div className='card-body'>
                            <div className='row'>
                                <section>
                                    <h2 className='promotion-name'>{name}</h2>
                                    <p className='promotion-description'>{description}</p>
                                    <p className='promotion-plan-address'>{address}</p>
                                    { aasm_state === 'active' && (
                                        <button
                                        onClick={()=> confirmFinishPromotion(id)}
                                        className={'button-secondary'}
                                        >
                                        {intl.get('FINISH_PROMOTION')}
                                        </button>
                                    )}
                                </section>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <section>
                                    <dl className=''>
                                        <dt>{intl.get('KIND')}</dt>
                                        <dd>{intl.get(kind.toUpperCase())}</dd>
                                    </dl>
                                </section>
                                <section>
                                    <dl>
                                        <dt className='inline-dl-title'>{intl.get('STATUS')}</dt>
                                        <dd className='inline-dl-value'>{state_to_show.toUpperCase()}</dd>
                                    </dl>
                                </section>
                            </div>
                            <div className='row'>
                                <section>
                                    <dl>
                                        <dt className='inline-dl-title'>{intl.get('START_DATE')}</dt>
                                        <dd className='inline-dl-value'>{starts_at}</dd>
                                    </dl>
                                </section>
                                <section>
                                    <dl>
                                        <dt className='inline-dl-title'>{intl.get('END_DATE')}</dt>
                                        <dd className='inline-dl-value'>{ends_at}</dd>
                                    </dl>
                                </section>
                            </div>
                        </div>
                        {hasPlan && <hr />}
                        {plans.map((opts, i) => (
                            <CardPlanDetails key={opts.kind} {...opts} />
                        ))}
                    </div>
                );
            })}{' '}
            {promotions.length === 0 && <p>{intl.get('NO_PROMOTIONS')}</p>}
            <ShowModal
                setStateFinishPromotion={setStateFinishPromotion}
                stateFinishPromotion={stateFinishPromotion}
                onCallback={onCallback}

            />
        </>
    );
};

export default CardPromotions;
