import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { createAdmin } from '../../redux/actions'
import intl from 'react-intl-universal'
import { toast } from 'react-toastify'
import {
  CardContainer,
  UserForm,
  DropZone,
  CircularLoading,
} from '../../tango-react-base/components'
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import {
  setTemplateChild,
  getCountriesCodes,
} from '../../tango-react-base/reduxActions'
import { SuperAdminReduxState } from '../../tango'
import { checkLogo } from '../../tango-react-base/resources/index'
import ButtonsHeader from '../../tango-react-base/components/buttonsHeader/index'
import { redirect_tabs } from '../buttonsOptions'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize'

type CreateUserProps = {
  onCreateAdmin: any
  fetchUser: string
  error_detail: any
  onSetTemplateChild: (child: Object) => void
  countriesCodes: any
  fetchingCodes: string
  onGetCountriesCodes: () => void
}

class File {
  file: any
  document_type: string
  constructor(file: any, document_type: string) {
    this.file = file
    this.document_type = document_type
  }
}

const fileTypes = [
  { label: 'Signed employment contract', type: 'employment_contract' },
  { label: 'Signed policies', type: 'signed_policies' },
]

const TYPE_ROLE = [
  { label: 'OPERATIONS_ADMIN', value: 'operations_admin' },
  { label: 'AGENT', value: 'agent' },
  { label: 'VERIFIER', value: 'verifier' },
]

const CreateUserView = ({
  onCreateAdmin,
  fetchUser,
  error_detail,
  onSetTemplateChild,
  countriesCodes,
  onGetCountriesCodes,
  fetchingCodes,
}: CreateUserProps) => {
  const history = useHistory()
  const size = useWindowSize()
  const [incompleteForm, setIncompleteForm] = useState(false)
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('CREATE_NEW_USER')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'create_new_user'}
          />
        </>,
      )
    }
  }, [onSetTemplateChild])

  useEffect(() => {
    onGetCountriesCodes()
  }, [])

  const [selectTypeRole, setselectTypeRole] = useState({
    operations_admin: false,
    agent: false,
    verifier: false,
  })
  const [documents, setDocuments] = useState<File[]>([])

  const onUploadImage = (fileType: string, index: number) => (e: any) => {
    const file = new File(e.target.files[0], fileType)
    documents[index] = file
    setDocuments([...documents])
  }

  const onDeleteImage = (index: number) => {
    delete documents[index]
    setDocuments([...documents])
  }

  const onOpenFile = (nameFile: string) => {
    let document_filer_und = documents.filter(
      (value: any) => value !== undefined,
    )
    let document_filer = document_filer_und.map((value: any) =>
      value.document_type === nameFile ? value : null,
    )
    let document = document_filer.filter((value: any) => value !== null)
    if (document.length > 0) {
      let tmppath = URL.createObjectURL(document[0].file)
      return tmppath
    }
  }

  const handleChange = (event: any) => {
    setselectTypeRole({
      operations_admin: event.target.value === 'operations_admin',
      agent: event.target.value === 'agent',
      verifier: event.target.value === 'verifier',
    })
  }

  const uploadPersonalPhoto = (event: any) => {
    const name = event.target.files[0].name
    const lastDot = name.lastIndexOf('.')
    const ext = name.substring(lastDot + 1)
    ext === 'jpg' || ext === 'png' || ext === 'jpeg'
      ? onUploadImage('user_photo', 2)(event)
      : toast.warn('Invalid format')
  }

  const extraData = () => {
    let json = {
      street_address: '',
      street_address_extra: '',
      verifier_ref: '',
      agent_ref: '',
    }
    return json
  }

  const HeaderCreateUser = () => {
    return (
      <>
        <div className='template-line-title' />
        <div
          className={`d-flex justify-content-between ${
            size.width <= 768 && 'flex-column'
          }`}
        >
          <h2 className='text my-2'>{intl.get('NEW_USER')}</h2>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='container-home pd-1 mt-5'>
        <HeaderCreateUser />
        <div className='mt-5'>
          <FormControl component='fieldset'>
            <RadioGroup row onChange={handleChange}>
              {TYPE_ROLE.map((value: any, key: number) => (
                <FormControlLabel
                  className='col'
                  control={<Radio value={value.value} />}
                  label={intl.get(value.label)}
                  key={key}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        {(selectTypeRole.operations_admin ||
          selectTypeRole.agent ||
          selectTypeRole.verifier) && (
          <CardContainer
            disabled_logo={true}
            grid_body={12}
            grid_card={12}
            card=''
            position_body='start'
          >
            <UserForm
              onSubmit={onCreateAdmin(selectTypeRole, documents)}
              disabled={false}
              child_input_admin={extraData()}
              selectTypeRole={selectTypeRole}
              fetchingCodes={fetchingCodes}
              countriesCodes={countriesCodes}
              setIncompleteForm={setIncompleteForm}
            >
              {(selectTypeRole.agent || selectTypeRole.verifier) && (
                <>
                  <h4 className='my-5'>{intl.get('DOCUMENTS')}</h4>
                  <div className='info-upload-files '>
                    <div
                      className='upload-line row col-12 mb-5'
                      key={`upl_line_${'user_photo'}`}
                      style={{ borderBottom: '1px solid #A8123E' }}
                    >
                      <span className='pr-5 span-text mb-5'>
                        {intl.get('PERSONAL_PHOTO')}
                      </span>
                      <DropZone
                        onUploadImage={uploadPersonalPhoto}
                        onDeleteImage={() => onDeleteImage(2)}
                        onOpenFile={onOpenFile('user_photo')}
                        accept={'image/jpg, image/jpeg, image/png'}
                        withFile={!documents[2] ? 'empty' : 'uploaded'}
                        className='justify-content-end'
                      />
                    </div>
                  </div>

                  <div className='info-upload-files'>
                    {fileTypes.map((fileType, index) => (
                      <div
                        className='row mb-4'
                        style={{ borderBottom: '1px solid #A8123E' }}
                        key={`upl_line_${fileType.type}`}
                      >
                        {!documents[index] ? (
                          <>
                            <p className='span-text'>{fileType.label}</p>
                          </>
                        ) : (
                          <div className='col-10 d-flex'>
                            <p className='span-text mr-2 text-break'>
                              {documents[index].file.name}
                            </p>
                            <img
                              src={checkLogo}
                              alt='checkLogo'
                              className='check-logo'
                            />
                          </div>
                        )}
                        <DropZone
                          onUploadImage={onUploadImage(fileType.type, index)}
                          onDeleteImage={() => onDeleteImage(index)}
                          onOpenFile={onOpenFile(fileType.type)}
                          withFile={!documents[index] ? 'empty' : 'uploaded'}
                          className='justify-content-end col-12'
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div className='d-flex justify-content-end'>
                {fetchUser !== 'FETCHING' ? (
                  <div className='col-12'>
                    {fetchUser === 'FETCHED_ERROR' && (
                      <p className='text-warning'>
                        {intl.get('CREATE_ERROR')}
                        {typeof error_detail === 'string' ||
                        error_detail instanceof String
                          ? error_detail
                          : Object.keys(error_detail).map(
                              (value: any, key: any) =>
                                `${key > 0 ? ',' : ''} ${value} error`,
                            )}
                      </p>
                    )}
                    <button
                      className='button-primary col-12 mt-5'
                      type='submit'
                    >
                      {intl.get('SAVE')}
                    </button>
                  </div>
                ) : (
                  <p> {<CircularLoading />}</p>
                )}
              </div>
            </UserForm>
          </CardContainer>
        )}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCreateAdmin: (selectTypeRole: any, documents: any) => (value: any) => {
      dispatch(createAdmin(value, selectTypeRole, documents))
    },
    onSetTemplateChild: (child: Object) => {
      return dispatch(setTemplateChild(child))
    },
    onGetCountriesCodes: () => {
      return dispatch(getCountriesCodes())
    },
  }
}

const mapStateToProps = (state: SuperAdminReduxState) => {
  return {
    fetchUser: state.userAdmin.fetch,
    error_detail: state.userAdmin.error.detail,
    countriesCodes: state.countriesCodes.codes.data,
    fetchingCodes: state.countriesCodes.fetchingCodes,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserView)
