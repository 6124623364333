export const getPromotionStart = () => {
  return {
    type: 'GET_PROMOTION_STARTED',
  }
}
export const getPromotionSuccess = (result: any) => {
  return {
    type: 'GET_PROMOTION_SUCCESS',
    payload: { result },
  }
}
export const getPromotionError = (error: any) => {
  return {
    type: 'GET_PROMOTION_ERROR',
    payload: {
      error,
    },
  }
}
