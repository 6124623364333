export const sendTokeStart = () => {
  return {
    type: 'SET_SEND_TOKEN_STARTED',
  }
}

export const sendTokeSuccess = () => {
  return {
    type: 'SET_SEND_TOKEN_SUCCESS',
    // payload: { session }
  }
}

export const sendTokeError = (error: any) => {
  return {
    type: 'SET_SEND_TOKEN_ERROR',
    payload: {
      error,
    },
  }
}
