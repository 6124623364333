import React, { useEffect, useState, Suspense } from 'react';
import { MenuItem } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { getNotificationUser, getRentState, logOutSession, navBarMenuAction } from '../../reduxActions';
import { tangoWhiteGreyLogo } from '../../resources/index';
import Notifier from './Notifier';
import { NotificationsDataProps } from './Interface';
import { isRoleOwner, isRoleCosignee, isRoleRenter } from '../../helpers/roleComparison/index';
import { CircularLoading, Sidebar } from '..';
const AppBarPrincipal = React.lazy(() => import('./AppBarPrincipal'));

interface NavBarProps {
    fetchStatus: string;
    onLogoutSession: any;
    children?: any;
    childrenMenuItem?: any;
    logo?: any;
    anchorEl: any;
    navBarMenu: any;
    anchorElPages: any;
    navBarMenuPages: any;
    notifications: Array<NotificationsDataProps>;
    fetchNotifications: string;
    email?: string;
    notificationActionList: Array<NotificationsDataProps>;
    showDrawer?: Object;
    buttonDrawer?: Object;
    summary?: any;
    onGetNotifications: Function;
    onGetRentState: (id: number) => void;
    userId?: number;
    rentRenterStep?: string;
    fetchingSummary?: string;
    fetchingRentRenterStep?: string;
}

const Navbar = ({
    fetchStatus,
    onLogoutSession,
    childrenMenuItem,
    anchorEl,
    navBarMenu,
    navBarMenuPages,
    notifications,
    fetchNotifications,
    notificationActionList,
    logo,
    onGetNotifications,
    onGetRentState,
    userId = 0,
    rentRenterStep = '',
    summary,
    fetchingSummary,
    fetchingRentRenterStep,
}: NavBarProps) => {
    let history = useHistory();
    let location = useLocation();
    const firstPath = location.pathname.split('/')[1];
    let session = sessionStorage.getItem('session');
    let new_session = session !== null ? JSON.parse(session) : {};
    let dataUser = new_session.attributes !== undefined ? new_session.attributes : {};

    useEffect(() => {
        if (fetchingRentRenterStep !== 'LOADED') {
            isRoleRenter && userId > 0 && onGetRentState(userId);
        }
    }, [userId, new_session, fetchingRentRenterStep]);

    const [anchorElNotifier, setAnchorElNotifier] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

    const openNotifier = Boolean(anchorElNotifier);
    const idNotifier = openNotifier ? 'simple-popover' : '';

    const [notificationNumber, setNotificationNumber] = useState(0);

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    let newNotifications = notifications.filter((value: NotificationsDataProps) => {
        let notificationList = notificationActionList.filter(
            (valueFilter: NotificationsDataProps) => valueFilter.id === value.id,
        );
        return notificationList.length === 0;
    });
    const appRole = process.env.REACT_APP_API_ROLE_APP;
    newNotifications = newNotifications.concat(notificationActionList);
    newNotifications = newNotifications.sort((a: any, b: any) => parseInt(b.id) - parseInt(a.id));
    const handleClickNotifier = (event: any) => {
        setAnchorElNotifier(event.currentTarget);
    };

    const url = dataUser.email !== undefined ? `login?email=${dataUser.email}` : 'login';
    let ownerUrl = `${process.env.REACT_APP_OWNER}${url}`;
    let renterUrl = `${process.env.REACT_APP_RENTER}${url}`;
    let adminUrl = `${process.env.REACT_APP_ADMIN_URL}${url}`;
    let superAdminUrl = `${process.env.REACT_APP_SUPER_ADMIN}${url}`;
    let validatorUrl = `${process.env.REACT_APP_VALIDATOR}${url}`;
    let agentUrl = `${process.env.REACT_APP_AGENT}${url}`;

    const validateConsignee = isRoleCosignee;
    const validateOwner = isRoleOwner;
    const validateRenter = isRoleRenter;

    // nav
    const open = Boolean(anchorEl);

    // para que no se caiga en las otras apps
    const logoAux = logo !== undefined ? logo : tangoWhiteGreyLogo;
    const [redirectToProperties, setRedirectToProperties] = useState('/');
    useEffect(() => {
        if (ownerUrl && fetchingSummary === 'FETCHED') {
            setRedirectToProperties(`/home/${summary.attributes.priority_posts_view}`);
        }
    }, [fetchingSummary]);

    useEffect(() => {
        const dataLocalStorageA = localStorage.getItem('authorization');
        const dataLocalStorageS = localStorage.getItem('session');
        const toLoginLocation = () => history.push('/login', { pathname: location.pathname });

        if (
            sessionStorage.getItem('authorization') === null &&
            dataLocalStorageA !== null &&
            dataLocalStorageS !== null
        ) {
            sessionStorage.setItem('authorization', dataLocalStorageA);
            sessionStorage.setItem('session', dataLocalStorageS);
        }
        const viewException = [
            'sign_up',
            'sign-up-instapage',
            'forget_password',
            'login',
            'terms-and-conditions',
            'about-us',
            'cosigner-approval',
            'thank_you',
            'short-register',
            'new-unit-creation',
            'new-unit-creation-instapage',
            'new-visits',
            'search-instapage',
            'fake-payment-method',
            'intention',
            'short-register',
            'not_found',
        ];
        const viewExceptionRenter = ['/new', '/advanced-search'];

        const validationToAll = !viewException.includes(firstPath) && location.pathname !== '/';

        if (!validateRenter && !validateOwner && !validateConsignee && session === null && validationToAll)
            toLoginLocation();

        if (
            validateOwner &&
            session === null &&
            location.pathname !== '/new' &&
            firstPath !== 'contract' &&
            firstPath !== 'admin_contract' &&
            validationToAll
        )
            toLoginLocation();

        if (
            appRole === 'RENTER' &&
            session === null &&
            !viewExceptionRenter.includes(location.pathname) &&
            firstPath !== 'posts' &&
            validationToAll
        )
            toLoginLocation();
    }, [appRole, firstPath, history, location.pathname, session, validateConsignee, validateOwner, validateRenter]);

    useEffect(() => {
        fetchNotifications === 'FETCHED_DETAIL' && onGetNotifications();
    }, [fetchNotifications]);

    useEffect(() => {
        if (fetchNotifications === 'FETCHED') {
            let filterByUnread = notifications.filter(
                (value: NotificationsDataProps) => value.attributes.aasm_state === 'unread',
            );
            setNotificationNumber(filterByUnread.length);
        }
    }, [fetchNotifications, notifications]);

    const { identity_document } = new_session?.attributes || {};
    const identityDocument = identity_document && Object.keys(identity_document).length > 0;
    const [redirectTo, setRedirectTo] = useState('/renter_profile/form');
    //** redirecciones dependiendo si el renter tiene documento subido y perfil enviado*/
    //* prioriza el envio del documento */
    useEffect(() => {
        const renterCreated =
            new_session?.attributes?.renter_state === 'created' || new_session?.attributes?.renter_state === null;

        if (identityDocument && renterCreated) {
            setRedirectTo('/renter_profile/form');
        } else if (!identityDocument && renterCreated) {
            setRedirectTo('/profile/documents');
        } else if (identityDocument && !renterCreated) {
            setRedirectTo('/renter_profile/form');
        }
    }, []);

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const getRoleItem = (value: any) => {
        switch (value) {
            case 'master_admin':
                return {
                    id: 'MASTER_ADMIN',
                    item: (
                        <MenuItem
                            key='3'
                            onClick={() => navBarMenuPages(null, () => (window.location.href = superAdminUrl))}
                        >
                            {intl.get('SWITCH_MASTERADMIN')}
                        </MenuItem>
                    ),
                };
            case 'operations_admin':
                return {
                    id: 'ADMIN',
                    item: (
                        <MenuItem
                            key='4'
                            onClick={() => navBarMenuPages(null, () => (window.location.href = adminUrl))}
                        >
                            {intl.get('SWITCH_OPERATION')}
                        </MenuItem>
                    ),
                };
            case 'accounting_admin':
                break;
            case 'agent':
                return {
                    id: 'BROKER',
                    item: (
                        <MenuItem
                            key='5'
                            onClick={() => navBarMenuPages(null, () => (window.location.href = agentUrl))}
                        >
                            {intl.get('SWITCH_AGENT')}
                        </MenuItem>
                    ),
                };
            case 'verifier':
                return {
                    id: 'VERIFIER',
                    item: (
                        <MenuItem
                            key='6'
                            onClick={() => navBarMenuPages(null, () => (window.location.href = validatorUrl))}
                        >
                            {intl.get('SWITCH_VERIFIER')}
                        </MenuItem>
                    ),
                };
            default:
                break;
        }
    };

    const itemNavigationPage = (customClass?: string) => {
        let item = [];
        if (validateRenter || isRoleOwner) {
            item.push({
                id: 'OWNER',
                item: (
                    <MenuItem
                        className={`text-color-secondary-bold ${customClass ? customClass : ''}`}
                        key='1'
                        onClick={() => navBarMenuPages(null, () => (window.location.href = ownerUrl))}
                    >
                        {intl.get('SWITCH_OWNER')}
                    </MenuItem>
                ),
            });
            item.push({
                id: 'RENTER',
                item: (
                    <MenuItem
                        className={`text-color-secondary-bold ${customClass ? customClass : ''}`}
                        key='2'
                        onClick={() => navBarMenuPages(null, () => (window.location.href = renterUrl))}
                    >
                        {intl.get('SWITCH_RENTER')}
                    </MenuItem>
                ),
            });
        } else if (session !== null) {
            item = new_session.attributes.user_roles.map((value: any) => getRoleItem(value));
        }
        item = item.filter((value: any) => value !== undefined);
        return item;
    };

    const handleShowSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    useEffect(() => {
        showSidebar ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
    }, [showSidebar]);

    return (
        <>
            <div className={`nav-bar-container ${isRoleOwner || isRoleRenter ? '' : 'mb-0'}`}>
                <Suspense fallback={<CircularLoading />}>
                    {((isRoleRenter && fetchingRentRenterStep === 'LOADED') ||
                        !Object.keys(dataUser).length ||
                        !isRoleRenter) && (
                        <AppBarPrincipal
                            logoAux={logoAux}
                            firstPath={firstPath}
                            validateRenter={validateRenter}
                            validateConsignee={validateConsignee}
                            fetchStatus={fetchStatus}
                            appRole={appRole}
                            navBarMenu={navBarMenu}
                            anchorEl={anchorEl}
                            open={open}
                            new_session={new_session}
                            childrenMenuItem={childrenMenuItem}
                            itemNavigationPage={itemNavigationPage}
                            idNotifier={idNotifier}
                            handleClickNotifier={handleClickNotifier}
                            openNotifier={openNotifier}
                            setNotificationNumber={setNotificationNumber}
                            notificationNumber={notificationNumber}
                            mobileMenuId={mobileMenuId}
                            handleMobileMenuOpen={handleMobileMenuOpen}
                            onLogoutSession={onLogoutSession}
                            handleShowSidebar={handleShowSidebar}
                            showSidebar={showSidebar}
                            newNotifications={newNotifications}
                            rentRenterStep={rentRenterStep}
                            redirectTo={redirectTo}
                        />
                    )}
                </Suspense>
            </div>
            <Notifier
                anchorElNotifier={anchorElNotifier}
                setAnchorEl={setAnchorElNotifier}
                open={openNotifier}
                id={idNotifier}
                notifications={newNotifications}
                fetchNotifications={fetchNotifications}
            />
            <Sidebar
                show={showSidebar}
                clicked={handleShowSidebar}
                new_session={new_session}
                onLogoutSession={onLogoutSession}
                redirectTo={redirectTo}
            />
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        fetchStatus: state.session.fetchStatus,
        anchorEl: state.navBar.anchorEl,
        anchorElPages: state.navBar.anchorElPages,
        notifications: state.notification.notifications,
        fetchNotifications: state.notification.fetch,
        notificationActionList: state.notification.notification_action_list,
        userId: state?.session?.user?.id,
        rentRenterStep: isRoleRenter ? state?.rentRenterStep?.rent_renter_step : '',
        fetchingSummary: state.ownerSummary?.fetchingSummary,
        summary: state.ownerSummary?.summary,
        fetchingRentRenterStep: isRoleRenter ? state?.rentRenterStep?.status : '',
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onLogoutSession: (history: any) => {
            return dispatch(logOutSession(history));
        },
        navBarMenu: (event: any, redirect?: any) => {
            return dispatch(navBarMenuAction(event, 'Same', redirect));
        },
        navBarMenuPages: (event: any, redirect?: any) => {
            return dispatch(navBarMenuAction(event, 'Pages', redirect));
        },
        onGetNotifications: () => {
            return dispatch(getNotificationUser());
        },
        onGetRentState: (id: number) => {
            return dispatch(getRentState(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
