import { getUserStart, getUserSuccess, getUserError } from './types'
import { getData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
const getUser = () => {
  return (dispatch: any, getState: any) => {
    dispatch(getUserStart())
    getData(`auth/users/user_profile`)
      .then((result: any) => {
        dispatch(getUserSuccess(result.data))
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(getUserError(e))
      })
  }
}
export default getUser
