import { createAdminStart, createAdminSuc, createAdminError } from './types'
import { multipartPost } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { getApplicationRole } from '../../../tango-react-base/helpers/getApplication'
import intl from 'react-intl-universal'

const createProfile = (data: any, selectTypeRole: any, documents: any) => {

  return (dispatch: any) => {
    dispatch(createAdminStart())
    if (!selectTypeRole.agent) {
      data.agent_id = ''
    }
    if (!selectTypeRole.verifier) {
      data.verifier_id = ''
    }
    let form_data = new FormData()
    let role = Object.keys(selectTypeRole).filter((key: any) => selectTypeRole[key])
    Object.keys(data).forEach((key: string) => {
      key !== 'selected_roles' && form_data.append(`user[${key}]`, data[key])
    }
    )
    form_data.append(`user[app]`, getApplicationRole(role))
    Object.keys(selectTypeRole).forEach((value: any) =>
      form_data.append(`role[${value}]`, selectTypeRole[value]),
    )
    documents = documents.filter((value: any) => value !== undefined)
    if (
      (selectTypeRole.agent || selectTypeRole.verifier) &&
      documents.length === 3
    ) {
      documents.forEach((value: any) => {
        form_data.append(`file[${value.document_type}]`, value.file)
      })
    }
    multipartPost(`auth/users`, form_data)
      .then((result) => {
        toast(intl.get('USER_REGISTERED'))
        dispatch(createAdminSuc(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(createAdminError(e.response.data.errors[0]))
      })
  }
}

export default createProfile
