export const setLogOutSuccess = (result: any) => {
  return {
    type: 'LOGOUT_USER_SUCCESS',
  }
}

export const setLogOutError = (error: any) => {
  return {
    type: 'LOGOUT_USER_ERROR',
    payload: {
      error
    },
  }
}
