import { multipartPost } from '../../../tango-react-base/helpers/apiConnections'
import { uploadFileStart, uploadFileSuccess, uploadFileError } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const uploadFile = (
  data: any,
  fileIndex: string,
  url: string,
  document_type: string,
  renter_profile_id: string,
) => {
  return (dispatch: any, getState: any) => {
    dispatch(uploadFileStart(fileIndex))
    let form_data = new FormData()
    form_data.append(`user_document[file]`, data)
    form_data.append(`user_document[user_id]`, renter_profile_id)
    form_data.append(`user_document[title]`, fileIndex)
    form_data.append(`user_document[document_type]`, document_type)
    multipartPost(url, form_data)
      .then((result) => {
        toast('File uploaded successfully!')
        dispatch(uploadFileSuccess(result.data, fileIndex))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(uploadFileError(e, fileIndex))
      })
  }
}
export default uploadFile
