import axios from 'axios';
export default async (url: string, data?: any) => {
    let api_url = `${process.env.REACT_APP_API_URL}`;
    let role = `${process.env.REACT_APP_API_ROLE_APP}`;
    const new_url = `${api_url.split('/').slice(0, -1).join('/')}`;
    let uri =
        url.split('/')[0] === 'auth' || url.split('/')[0] === 'toc'
            ? api_url.replace(role.toLowerCase(), `${url}`)
            : `${new_url}/${url}`;
    let headers: any = {
        authorization: sessionStorage.getItem('authorization'),
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
    };
    return axios.post(uri, data, { headers });
};
