export const putOwnerPaymentStart = (id: string) => {
  return {
    type: 'PUT_OWNER_PAYMENT_START',
    payload: { id },
  }
}

export const putOwnerPaymentSuccess = (result: any, id: string, countrymanagerName: string) => {
  return {
    type: 'PUT_OWNER_PAYMENT_SUCCESS',
    payload: { result, id, countrymanagerName },
  }
}
export const putReferencesPaymentStart = (id: string) => {
  return {
    type: 'PUT_REFERENCES_PAYMENT_START',
    payload: { id },
  }
}

export const putReferencesPaymentSuccess = (result: any, id: string, countrymanagerName: string) => {
  return {
    type: 'PUT_REFERENCES_PAYMENT_SUCCESS',
    payload: { result, id, countrymanagerName },
  }
}
