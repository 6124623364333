export const redirect_tabs = [
  {
    containerName: 'col-12 p-0 d-flex flex-wrap',
    buttons: [
      {
        label: 'transactions',
        viewLabel: 'TRANSACTIONS',
        to: '/transactions',
      },
      {
        label: 'promotions',
        viewLabel: 'PROMOTIONS',
        to: '/promotions',
      },
      {
        label: 'create_new_user',
        viewLabel: 'CREATE_NEW_USER',
        to: '/create_user',
      },
    ],
  },
]
