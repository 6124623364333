import { IpLocationState } from '../../../tango'

const initialState: IpLocationState = {
  ipLocationData: {},
  fetchingLocation: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_LOCATION_DATA_STARTED':
      return {
        ...state,
        fetchingLocation: 'LOADING',
      }
    case 'GET_LOCATION_DATA_SUCCESS':
      return {
        ...state,
        ipLocationData: action.payload.data,
        fetchingLocation: 'LOADED',
      }
    case 'GET_LOCATION_DATA_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingLocation: 'ERROR',
      }
    default:
      return state
  }
}
