import putData from '../../../tango-react-base/helpers/apiConnections/putData'
import {
  updatePromotionStart,
  updatePromotionSuccess,
  updatePromotionError,
} from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const updatePromotion = (data: any, id: number) => {
  return (dispatch: any) => {
    dispatch(updatePromotionStart())
    let promotion = {
      promotion: {
        name: data.name,
        description: data.description,
        administration_fee_new_discount: data.administration_fee_new_discount,
        administration_fee_registered_discount:
          data.administration_fee_registered_discount,
        collection_fee_new_discount_owner: data.collection_fee_new_discount_owner,
        collection_fee_new_discount_renter: data.collection_fee_new_discount_renter,
        new_discount_duration: data.new_discount_duration,
        registered_discount_duration: data.registered_discount_duration,
        starts_at: data.starts_at,
        ends_at: data.ends_at,
        kind: data.kind,
        address: data.address,
        collection_fee_brokerage_discount_owner: data.collection_fee_brokerage_discount_owner,
        collection_fee_brokerage_discount_renter: data.collection_fee_brokerage_discount_renter,
      },
    }

    putData(`promotions/${id}`, promotion)
      .then((result) => {
        dispatch(updatePromotionSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(updatePromotionError(e))
      })
  }
}
export default updatePromotion
