export const approveDocumentStart = () => {
    return {
        type: 'CHANGE_UNIT_STARTED',
    };
};

export const approveDocumentSuc = (property_comments: string) => {
    return {
        type: 'CHANGE_UNIT_SUCCESS',
        payload: {
            property_comments,
        },
    };
};
