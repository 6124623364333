import { isRoleRenter } from '../../../helpers/roleComparison/index'
export const getUserStart = () => {
  if (isRoleRenter) {
    return {
      type: 'GET_USER_PROFILE_STARTED',
    }
  } else {
    return {
      type: 'GET_USER_DATA_STARTED',
    }
  }
}

export const getUserSuccess = (user: any) => {
  if (isRoleRenter) {
    return {
      type: 'GET_USER_PROFILE_SUCCESS',
      payload: { user },
    }
  } else {
    return {
      type: 'GET_USER_DATA_SUCCESS',
      payload: { user },
    }
  }
}

export const getUserError = (error: any) => {
  if (isRoleRenter) {
    return {
      type: 'GET_USER_PROFILE_ERROR',
      payload: {
        error,
      },
    }
  } else {
    return {
      type: 'GET_USER_DATA_ERROR',
      payload: {
        error,
      },
    }
  }
}

export const updateRentingState = () => {
  return {
    type: 'UPDATE_RENTING_STATE'
  }
}
