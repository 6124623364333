import React, { useState } from 'react';
import '../../scss/main.scss';
import intl from 'react-intl-universal';
import { withStyles, InputAdornment, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { isRequired } from '../../helpers/fieldValidations';
import { Field } from 'formik';
import NumberFormat from 'react-number-format';
import { includeInstapagePath } from '../../helpers/routesForInstapage';
import { isRoleAdmin, isRoleOwner, isRolePhotographer, isRoleRenter } from '../../helpers/roleComparison';
import { useHistory, useLocation } from 'react-router-dom';
import { ChileFlag, UploadDisableLogo, uploadLogo } from '../../resources';

interface InputProps {
    label?: string;
    placeholder_intl?: string;
    bottomLabel?: string;
    id: string;
    placeholder?: string;
    onChange?: any;
    value: any;
    name?: string;
    type?: string;
    error?: string;
    touched?: any;
    onBlur?: any;
    min?: string;
    max?: string;
    className?: string;
    requiredMessage?: string;
    multiline?: boolean;
    disabled?: boolean;
    rows?: number;
    maxLength?: number | string;
    variant?: boolean;
    labelInput?: string;
    unit?: string;
    info?: string;
    classNameLabel?: string;
    classNameInput?: string;
    required?: boolean;
    setFieldValue?: any;
    disabledAutocomplete?: boolean;
    messageAdditionalValidation?: string;
    children?: any;
    justifyCenter?: string;
    childrenClassName?: string;
    isVerifier?: boolean;
    isRequiredVerifier?: boolean;
    requiredInstapage?: boolean;
    classNameMultiline?: string;
    onClick?: any;
    colorTertiary?: boolean;
    classNameFlagContainer?: string;
    noTextTransformation?: boolean;
    textAdornament?: string;
    iconPosition?: string;
}

let borderColor = includeInstapagePath() ? '#FFFFFF' : !isRoleRenter && !isRoleOwner ? '#A8123E' : '#999999';

const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: borderColor,
        },
        '& .MuiTextField-root': {
            color: borderColor,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: borderColor,
        },
        '& label.Mui-focused': {
            color: 'transparent',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: borderColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: borderColor,
            },
            '&:hover fieldset': {
                borderColor: borderColor,
            },
            '&.Mui-focused fieldset': {
                borderColor: 'borderColor',
            },
        },
        '& .MuiFormLabel-root': {
            zIndex: 0,
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: 'small',
            marginLeft: 4,
            position: 'absolute',
            bottom: -20,
        },
    },
})(TextField);

const Input = ({
    placeholder,
    touched,
    bottomLabel,
    placeholder_intl,
    label,
    className,
    error,
    requiredMessage,
    variant = true,
    unit,
    setFieldValue,
    labelInput,
    info,
    value = '',
    multiline = false,
    classNameLabel = 'col-md-4 col-12 pl-0 mb-1',
    classNameInput = 'col-lg-3 plm-0 ',
    type = 'text',
    justifyCenter = 'align-items-start',
    maxLength,
    min,
    max,
    disabledAutocomplete,
    messageAdditionalValidation = '',
    children,
    childrenClassName,
    isVerifier = false,
    isRequiredVerifier = false,
    requiredInstapage = false,
    classNameMultiline,
    onClick,
    classNameFlagContainer = '',
    colorTertiary = false,
    noTextTransformation = false,
    textAdornament = '',
    iconPosition = 'start',
    ...inputProps
}: InputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const routesForColorTertiary = ['login', 'about-us', 'sign_up', 'forget_password', 'new-unit-creation'];
    const colorTertiaryPathIncluded = routesForColorTertiary.includes(currentLocation);

    const colorStyle = (isRoleRenter || isRoleOwner) && colorTertiaryPathIncluded;

    return (
        <Field name={inputProps.id} validate={!!requiredMessage && isRequired(requiredMessage) && !isRolePhotographer}>
            {({ field, form }: any) => {
                const changeValue = (value: any) => {
                    form.setFieldValue(inputProps.id, value.floatValue);
                };
                let variantText: any = variant ? 'outlined' : undefined;
                return (
                    <>
                        <div onClick={onClick} className={`row input-container d-flex ${justifyCenter} ${className}`}>
                            <>
                                {label !== undefined && label !== '' && (
                                    <div className={classNameLabel}>
                                        <p
                                            className={
                                                includeInstapagePath()
                                                    ? 'text-color-white'
                                                    : !colorStyle && !colorTertiary
                                                    ? 'text-color-secondary'
                                                    : 'input-color-tertiary'
                                            }
                                        >
                                            {`${
                                                !colorStyle && (colorTertiary || noTextTransformation)
                                                    ? intl.get(`${label}`)
                                                    : intl.get(`${label}`).toUpperCase()
                                            }${isVerifier || requiredInstapage ? '' : ''}`}
                                        </p>
                                        <p className='p-info-label'>{info !== '' ? intl.get(`${info}`) : info}</p>
                                    </div>
                                )}
                                <div className={unit !== undefined ? `${classNameInput}  col-6` : `${classNameInput}`}>
                                    {type === 'price' && (
                                        <NumberFormat
                                            id={inputProps.id}
                                            className=''
                                            placeholder={placeholder}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            allowNegative={false}
                                            defaultValue={value ? value : ''}
                                            prefix={'$'}
                                            required={inputProps.required}
                                            onValueChange={(value) => changeValue(value)}
                                            disabled={inputProps.disabled}
                                            customInput={BootstrapInput}
                                            variant={variantText}
                                            helperText={error && intl.get(error)}
                                        />
                                    )}
                                    {type === 'upload_button_file' && (
                                        <BootstrapInput
                                            placeholder={placeholder}
                                            label={value ? '' : labelInput}
                                            id={`bootstrap-input-${inputProps.id}`}
                                            error={!!error}
                                            helperText={error && intl.get(error)}
                                            size='small'
                                            multiline={multiline}
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            className={classNameMultiline}
                                            type={'icon'}
                                            value={value || value >= 0 ? value : ''}
                                            autoComplete={disabledAutocomplete ? 'off' : undefined}
                                            InputProps={{
                                                [`${iconPosition}Adornment`]: (
                                                    <InputAdornment position='start'>
                                                        <img
                                                            className='input-with-image'
                                                            src={inputProps.disabled ? UploadDisableLogo : uploadLogo}
                                                        />
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true,
                                                ...inputProps,
                                            }}
                                            inputProps={{
                                                maxLength,
                                                min,
                                                max,
                                            }}
                                        />
                                    )}

                                    {(inputProps.id === 'phone_number' ||
                                        inputProps.id === 'janitor_phone_number' ||
                                        type === 'phone_number') && (
                                        <div className='number-format'>
                                            {classNameFlagContainer && (
                                                <div
                                                    className={` d-flex align-items-center justify-content-center col-3 p-0 ${classNameFlagContainer}`}
                                                >
                                                    <img src={ChileFlag} alt='Chile flag' width={30} />
                                                    <span className='number-code'>+56</span>
                                                    <div className='vertical-line' />
                                                </div>
                                            )}
                                            <NumberFormat
                                                className=''
                                                id={inputProps.id}
                                                placeholder={placeholder}
                                                defaultValue={value ? value : ''}
                                                required
                                                format='# ## ### ###'
                                                allowEmptyFormatting={true}
                                                onValueChange={(value) => changeValue(value)}
                                                customInput={BootstrapInput}
                                                variant={variantText}
                                                disabled={inputProps.disabled}
                                                helperText={error && intl.get(error)}
                                            />
                                        </div>
                                    )}

                                    {inputProps.id !== 'phone_number' &&
                                        inputProps.id !== 'janitor_phone_number' &&
                                        type !== 'price' &&
                                        type !== 'upload_button_file' &&
                                        type !== 'phone_number' && (
                                            <BootstrapInput
                                                placeholder={placeholder}
                                                label={value ? '' : labelInput}
                                                id={`bootstrap-input-${inputProps.id}`}
                                                error={!!error}
                                                helperText={error && intl.get(error)}
                                                variant={variantText}
                                                size='small'
                                                multiline={multiline}
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                                className={classNameMultiline || ''}
                                                type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                                                value={value || value >= 0 ? value : ''}
                                                autoComplete={disabledAutocomplete ? 'off' : undefined}
                                                InputProps={{
                                                    endAdornment:
                                                        (type === 'password' && (
                                                            <InputAdornment position='end'>
                                                                <IconButton
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? (
                                                                        <i
                                                                            className={`fa fa-eye ${
                                                                                includeInstapagePath()
                                                                                    ? 'text-color-white'
                                                                                    : !colorStyle
                                                                                    ? 'text-color-secondary'
                                                                                    : 'input-color-tertiary'
                                                                            }`}
                                                                        />
                                                                    ) : (
                                                                        <i
                                                                            className={`fa fa-eye-off ${
                                                                                includeInstapagePath()
                                                                                    ? 'text-color-white'
                                                                                    : !colorStyle
                                                                                    ? 'text-color-secondary'
                                                                                    : 'input-color-tertiary'
                                                                            }`}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )) ||
                                                        (type === 'number_with_adornament' && (
                                                            <InputAdornment position='end'>
                                                                {textAdornament}
                                                            </InputAdornment>
                                                        )),
                                                    ...inputProps,
                                                }}
                                                inputProps={{
                                                    maxLength,
                                                    min,
                                                    max,
                                                }}
                                            />
                                        )}
                                    {messageAdditionalValidation && (
                                        <div className='text col-12 pl-0'>
                                            <p
                                                className={`${
                                                    includeInstapagePath() ? 'text-color-white' : 'text-color-error'
                                                } small m-0`}
                                            >
                                                {messageAdditionalValidation}
                                            </p>
                                        </div>
                                    )}
                                    {(isVerifier || isRoleAdmin) && isRequiredVerifier && (
                                        <div className='text col-12 pl-0'>
                                            <p
                                                className={`${
                                                    includeInstapagePath() ? 'text-color-white' : 'text-color-error'
                                                } small m-0`}
                                            >
                                                {intl.get('REQUIRED_FIELD')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                {bottomLabel !== '' && <span className='span-text'>{intl.get(`${bottomLabel}`)}</span>}
                                {unit !== undefined && (
                                    <span className='pl-1 span-text super-index mlp-sm-1'>
                                        {` ${unit}`}
                                        {unit === 'm' && <sup>2</sup>}
                                    </span>
                                )}
                                {childrenClassName !== '' ? (
                                    <div className={childrenClassName}>{children}</div>
                                ) : (
                                    { children }
                                )}
                            </>
                        </div>
                    </>
                );
            }}
        </Field>
    );
};

export default Input;
