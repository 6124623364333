export const CancelVisitStart = () => {
    return {
        type: 'CANCEL_VISIT_STARTED',
    };
};

export const CancelVisitSuccess = (result: any) => {
    return {
        type: 'CANCEL_VISIT_SUCCESS',
        payload: {
            result,
        },
    };
};
export const CancelVisitError = (error: any) => {
    return {
        type: 'CANCEL_VISIT_ERROR',
        payload: {
            error,
        },
    };
};
